<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "add",
  data() {
    return {};
  },
  mounted() {
    window.addEventListener(
      "hashchange",
      () => {
        var currentPath = window.location.hash.slice(1); // 获取输入的路由
        if (this.$router.path !== currentPath) {
          this.$router.push(currentPath); // 动态跳转
        }
      },
      false
    );
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  // min-width: 1700px;
}
// img {
//   width: 100%;
//   height: 100%;
//   display: block;
// }
p,
ul {
  margin: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.width_common {
  width: 1190px;
  margin: 0 auto;
}
</style>
