<template>
  <div class="headNav">
    <img
      @click="getActive('Inicio')"
      class="logo"
      src="@/assets/image/logo.png"
      alt=""
    />
    <ul class="nav">
      <li :class="activeNav == 'home' ? 'active' : ''">
        <a @click="getActive('home')">Home</a>
      </li>
      <li :class="activeNav == 'about' ? 'active' : ''">
        <a @click="getActive('about')">About US</a>
      </li>
      <li :class="activeNav == 'FAQ' ? 'active' : ''">
        <a @click="getActive('FAQ')">FAQs</a>
      </li>
      <li
        :class="
          activeNav == 'Notify' || activeNav == 'Calculator' ? 'active' : ''
        "
        class="otherFunc"
      >
        <el-dropdown
          :class="
            activeNav == 'Notify' || activeNav == 'Calculator'
              ? 'operateActive'
              : ''
          "
          @command="pushOtherFunc"
        >
          <span class="el-dropdown-link">
            {{ nowPerate }} <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in operateFuncList"
              :key="index"
              :command="item"
              :style="{
                backgroundColor: nowPerate == item ? '#2ea76a' : '',
                color: nowPerate == item ? '#fff' : '',
                margin: '5px 0',
              }"
            >
              <i
                style="display:inline-block;width:15px"
                :class="[nowPerate == item ? 'el-icon-check' : '']"
              ></i>
              {{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li :class="activeNav == 'complaints' ? 'active' : ''">
        <a @click="getActive('complaints')">Complaints</a>
      </li>
      <li
        :class="activeNav == 'transparencia' ? 'active' : ''"
        class="lang"
        @click="onLang"
      >
        <el-dropdown
          trigger="click"
          :append-to-body="false"
          @command="
            ($event) => {
              handleCommand($event, 'click');
            }
          "
        >
          <span class="el-dropdown-link">
            En <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <div
              v-for="(item, index) in list"
              :key="index"
              @mouseenter="handleCommand(index)"
            >
              <el-dropdown-item
                :icon="active_blog == index ? 'el-icon-check' : ''"
                :command="index"
              >
                <div
                  class="text"
                  :class="[active_blog == index ? '' : 'activeIcon']"
                >
                  <!-- :style="{ marginLeft: active_blog == index ? '' : '18px' }" -->
                  {{ item }}
                </div>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
    <img
      @click="getActive('Inicio')"
      class="logo_jing"
      src="@/assets/image/logo_jing.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "headNav",
  props: ["activeNav"],
  data() {
    return {
      active_blog: 0,
      list: ["En", "اردو"],
      nowPerate: "Service",
      operateFuncList: ["Notify", "Calculator"],
    };
  },
  created() {
    if (this.activeNav == "Notify" || this.activeNav == "Calculator") {
      this.nowPerate = this.activeNav;
    }
  },
  methods: {
    getActive(val) {
      let url = "";
      switch (val) {
        case "home":
          url = "/home";
          break;
        case "about":
          url = "/about";
          break;
        case "FAQ":
          url = "/FAQ";
          break;
        case "complaints":
          url = "/complaints";
          break;
      }
      this.$router.push(url);
    },
    handleCommand(val, event) {
      this.active_blog = val;
      if (event == "click") {
        if (val == 1) {
          if (this.$route.path.includes("/urdo"))
            this.$router.push(this.$route.path);
          else this.$router.push(this.$route.path + "/urdo");
        } else this.$router.push(this.$route.path.replace("/urdo", ""));
      }
    },
    onLang() {
      this.active_blog = 0;
    },
    pushOtherFunc(row) {
      this.nowPerate = row;
      // 变小写
      this.$router.push("/" + row.toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 750px) {
  html {
    font-size: 100px;
  }

  img {
    display: none;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // height: 0.88rem;
    padding: 0.28rem 0.19rem 0.28rem 0.38rem;
    li {
      margin-right: 0.57rem;
      padding-bottom: 0.09rem;
    }
    li:last-child {
      margin-right: 0;
    }
    li,
    .lang .el-dropdown-link {
      font-size: 0.35rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #2a2a2a;
      // &:hover {
      //   color: #2ea76a;
      //   border-bottom: 0.03rem solid #2ea76a;
      // }
    }
    .active {
      color: #2ea76a;
      border-bottom: 0.03rem solid #2ea76a;
    }
  }
  .el-dropdown-menu {
    background: #d8d8d8;
    border: 1px solid #979797;
    // padding-bottom: 0.2rem;
    div {
      margin: 0.15rem 0.1rem;
      // width: calc(100% - 0.2rem);
      height: 50%;
    }
  }

  .el-dropdown-menu__item {
    font-size: 0.35rem;
    // text-align: center;
    width: calc(100% - 0.1rem);

    .text {
      display: inline-block;
      margin-left: 0 !important;
    }
  }
  .otherFunc {
    display: none;
  }
}

@media only screen and (min-width: 750px) {
  .headNav {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    box-sizing: border-box;
    .logo {
      width: 370px;
    }
    .logo_jing {
      width: 229px;
      height: 60px;
    }
    .nav {
      display: flex;
      align-items: center;
      margin: 0 43px 0 86px;
      li {
        margin-right: 90px;
      }
      li:last-child {
        margin-right: 0;
      }
      li,
      .lang .el-dropdown-link {
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        color: #333333;
        &:hover {
          color: #2ea76a;
          border-bottom: 2px solid #2ea76a;
        }
      }
      .active {
        color: #2ea76a;
        border-bottom: 2px solid #2ea76a;
      }
    }
  }

  .activeIcon {
    margin-left: 20px;
  }
  /deep/.el-dropdown-menu__item {
    display: flex;
    align-items: center;
    padding-left: 8px;

    &:hover {
      display: flex;
      align-items: center;
      background: #2ea76a !important;
      color: #ffffff !important;
    }
  }
  .el-dropdown-menu {
    background: #d8d8d8;
    border: 1px solid #979797;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 11px;
  }
  /deep/.popper__arrow {
    display: none !important;
  }
  .otherFunc {
    /deep/ .el-dropdown {
      font-size: 20px !important;
      color: #222;
      &:hover {
        display: flex;
        align-items: center;
        color: #2ea76a !important;
      }
    }
    .operateActive {
      color: #2ea76a;
    }
  }
}

// .active_color {

//   background: #2ea76a;
//   color: #ffffff;
// }

// .li_blog {
//   display: flex;
//   align-items: center;
//   .el-dropdown-link {
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     img {
//       width: 12px;
//       height: 8px;
//       margin-left: 10px;
//     }
//   }
// }
// .el-dropdown-menu {
//   padding: 0;
//   li {
//     width: 320px;
//     background: #fff;
//     font-size: 16px;
//     font-family: PingFangSC-Regular, PingFang SC;
//     font-weight: 400;
//     color: #666666;
//     text-align: center;
//     padding: 20px 0;
//     box-sizing: border-box;
//     border-bottom: 1px solid #edecec;
//     &:nth-child(3) {
//       border-bottom: none !important;
//     }
//     &:hover {
//       color: #19a95c;
//     }
//   }
//   .active_color {
//     color: #19a95c;
//     font-size: 16px;
//     font-family: PingFangSC-Semibold, PingFang SC;
//     font-weight: 600;
//   }
// }
</style>
