<template>
  <div>
    <Header class="headerBox" />
    <div class="banner">
      <img
        style="width:100%"
        src="../../assets/newImage/complaints.png"
        alt=""
      />
    </div>
    <!-- Form Box -->
    <div
      class="submitContent"
      v-loading="loading"
      element-loading-text="loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div>
        <h3>Complaint Details</h3>
        <!-- Name -->
        <div class="iptBox">
          <p><span>*</span>Name</p>
          <el-input
            placeholder="Please enter your name"
            v-model="params.nameData"
          ></el-input>
          <!-- title -->
          <div class="title" style="color: #e52118" v-if="nameShow">
            Please enter your name
          </div>
        </div>
        <!-- Login Mobile Number -->
        <div class="iptBox">
          <p><span>*</span>Login Mobile Number</p>
          <el-input
            placeholder="Please enter 10 digits starting from 3"
            v-model="params.mobileData"
          ></el-input>
          <!-- title -->
          <div class="title" style="color: #e52118" v-if="mobileShow">
            Please enter 10 digits starting from 3
          </div>
          <div class="title" style="color: #e52118" v-if="mobileLengthShow">
            Incorrect format of Mobile Number
          </div>
        </div>
        <!-- Login Mobile Number -->
        <div class="iptBox" style="height:auto">
          <p><span>*</span>Problem Description</p>
          <el-input
            type="textarea"
            resize="none"
            placeholder="Please enter problem description"
            v-model="params.problemData"
          ></el-input>
          <!-- title -->
          <div
            class="title"
            style="color: #e52118"
            v-show="problemDescriptionShow"
          >
            Please enter problem description
          </div>
        </div>
        <p style="font-size:20px;color:#b3b3b3;width:1250px">
          If you need to cancel your account, you can submit your information
          here for review. After the customer service review is approved, your
          account will be canceled within 5 working days.
        </p>
        <!-- submit BTN -->
        <img
          class="subBtn"
          @click="submitForm"
          src="../../assets/newImage/btn.png"
          alt=""
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/newHeader.vue";
import Footer from "../../components/newFooter.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      apiHead: "",
      params: {
        nameData: "", //投诉人姓名
        mobileData: "", //投诉人手机号
        problemData: "", //投诉内容描述
      },
      nameShow: false,
      mobileShow: false,
      mobileLengthShow: false,
      problemDescriptionShow: false,
      loading: false,
    };
  },
  methods: {
    // 提交
    submitForm() {
      if (this.params.nameData == "") this.nameShow = true;
      else this.nameShow = false;

      if (this.params.mobileData == "") {
        this.mobileShow = true;
        this.mobileLengthShow = false;
      } else {
        this.mobileShow = false;
        if (
          this.params.mobileData.toString().charAt(0) != "3" ||
          this.params.mobileData.toString().length < 10
        ) {
          this.mobileLengthShow = true;
          this.mobileLengthShow = false;
        } else this.mobileLengthShow = false;
      }

      if (this.params.problemData == "") this.problemDescriptionShow = true;
      else this.problemDescriptionShow = false;

      //添加投诉意见
      if (
        !this.nameShow &&
        !this.mobileShow &&
        !this.mobileLengthShow &&
        !this.problemDescriptionShow
      ) {
        this.loading = true;
        axios
          .post(this.apiHead + "v3/complaint/add", {
            ...this.params,
          })
          .then((res) => {
            if (res.data.code == 0) {
              if (res.data.data.status == 1) {
                this.$message.success({
                  offset: 300,
                  message: "Submit successfully",
                  center: true,
                });
              }
            }
            this.loading = false;
          });
      }
    },
    // 初始化
    envInit() {
      if (
        window.location.host == "localhost:8080" ||
        window.location.host == "paisayaar.jinglewill.com"
      ) {
        this.apiHead = "https://api-test-pk.jinglewill.com/gateway/ApiService/";
      } else {
        this.apiHead = "https://api.paisayaar.pk/gateway/ApiService/";
      }
      if (window.android) {
        this.account_token = window.android.getAccessToken();
      }
    },
  },
  mounted() {
    this.envInit();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.headerBox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
}
.submitContent {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 1155px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px 40px 0px 0px;
  margin-top: -50px;
  padding-top: 119px;
  z-index: 10;
  h3 {
    font-size: 36px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #333333;
    margin-bottom: 32px;
    text-align: centerf;
  }
  .el-input {
    width: 100%;
    height: 71px;
    border-radius: 10px;
    border: 2px solid #b3b3b3;
    margin-bottom: 15px;
    display: block;
  }
  .el-textarea {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    border: 2px solid #999999;
    margin-bottom: 20px;
  }
  .iptBox {
    height: 180px;
    margin-bottom: 12px;
    p {
      font-size: 22px;
      font-family: OpenSans-SemiBold, OpenSans;
      font-weight: 600;
      color: #333333;
      margin-bottom: 24px;
      span {
        color: #e90909;
      }
    }
    .title {
      font-size: 20px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
    }
  }
}
/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  padding-left: 32px;
}
/deep/ .el-textarea__inner {
  padding-top: 20px;
}

.subBtn {
  width: 378px;
  height: 120px;
  margin: 64px auto 0;
  cursor: pointer;
  display: block;
}
</style>
