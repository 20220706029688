import Vue from "vue";
import VueRouter from "vue-router";
// import home from "@/views/home.vue";
// import homeUrdo from "@/views/homeUrdo.vue";
import about from "@/views/about.vue";
import aboutUrdo from "@/views/aboutUrdo.vue";
// import faq from "@/views/FAQ.vue";
// import faqUrdo from "@/views/FAQUrdo.vue";
// import complaints from "@/views/complaints.vue";
// import complaintsUrdo from "@/views/complaintsUrdo.vue";
import notify from "@/views/notify.vue";
import notifyUrdo from "@/views/notifyUrdo.vue";
import calculator from "@/views/calculator.vue";
import calculatorUrdo from "@/views/calculatorUrdo.vue";

import home from "@/views/newPages/home.vue";
import homeUrdo from "@/views/newPages/homeUrdo.vue";
import complaints from "@/views/newPages/complaints.vue";
import complaintsUrdo from "@/views/newPages/complaintsUrdo.vue";
import faq from "@/views/newPages/faq.vue";
import faqUrdo from "@/views/newPages/faqUrdo.vue";
import servise from "@/views/newPages/servise.vue";
import serviseUrdo from "@/views/newPages/serviseUrdo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: home,
  },
  {
    path: "/homeUrdo",
    name: "homeUrdo",
    component: homeUrdo,
  },
  {
    path: "/about",
    name: "about",
    component: about,
  },
  {
    path: "/about/urdo",
    name: "aboutUrdo",
    component: aboutUrdo,
  },
  {
    path: "/faq",
    name: "faq",
    component: faq,
  },
  {
    path: "/faqUrdo",
    name: "faqUrdo",
    component: faqUrdo,
  },
  {
    path: "/complaints",
    name: "complaints",
    component: complaints,
  },
  {
    path: "/complaintsUrdo",
    name: "complaintsUrdo",
    component: complaintsUrdo,
  },
  {
    path: "/notify",
    name: "notify",
    component: notify,
  },
  {
    path: "/calculator",
    name: "calculator",
    component: calculator,
  },
  {
    path: "/notify/urdo",
    name: "notifyUrdo",
    component: notifyUrdo,
  },
  {
    path: "/calculator/urdo",
    name: "calculatorUrdo",
    component: calculatorUrdo,
  },
  {
    path: "/servise",
    name: "servise",
    component: servise,
  },
  {
    path: "/serviseUrdo",
    name: "serviseUrdo",
    component: serviseUrdo,
  },
];

const router = new VueRouter({
  mode: process.env.VITE_BASE_MODE,
  // base: process.env.BASE_URL,
  routes,
});

console.log(process.env);

export default router;
