<template>
  <div>
    <HeaderUrdo activeNav="NotifyUrdo" />
    <!-- banner 大盒子 -->
    <div class="numberVerify">
      <!-- 计算内容 -->
      <div class="contentBox">
        <!-- 输入框 -->
        <div style="display: flex; justify-content: space-between">
          <el-button
            :disabled="phoneNum ? false : true"
            :style="{ background: phoneNum ? '#2ea76a' : '#b3b3b3' }"
            class="verifyBtn"
            @click="verifyBtn"
            >انکوائری نمبر</el-button
          >
          <el-input
            class="numIpt"
            @input="changeIpt"
            maxlength="12"
            v-model="phoneNum"
            clearable
          ></el-input>
        </div>
        <div class="titleStatus">
          <div v-show="verifyStatus == 1">
            <span style="color: #2ea76a"> منظم طور تصدیق شدہ نمبر </span>
            <img src="../assets/image/service/success.png" alt="" />
          </div>
          <div v-show="verifyStatus == 0">
            <span style="color: #e73400"> غیر منظم طور پر تصدیق شدہ نمبر </span>
            <img src="../assets/image/service/error.png" alt="" />
          </div>
        </div>
        <!-- 电话介绍 -->
        <div class="phoneIntroduce">
          <h3>آفیشل فون نمبر</h3>
          <div class="phoneBox interval">
            <p>0518841270 - 0518841289</p>
            <p>0516011632 - 0516011932</p>
            <p>03205690243 - 03205690272</p>
          </div>
          <div class="phoneBox">
            <p>03088888260</p>
            <p>051111883883</p>
            <p>0518841270</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 介绍文案 -->
    <div class="introduce">
      <div class="introduceContent">
        <!-- 持牌运营机构 -->
        <h2>لائسنس یافتہ آپریٹنگ ادارہ</h2>
        <p>
          پیسہ یار ایک آن لائن قرض کا ادارہ ہے جو SECP کے ساتھ رجسٹرڈ ہے اور
          NBFC کے ساتھ تصدیق شدہ ہے، جس کے ذریعے آپ محفوظ اور مسترد قرض حاصل
          کرسکتے ہیں۔ تمام قرض کے اخراجات کھل کر شفاف ہیں، کوئی پوشیدہ فیس نہیں
          ہے۔
        </p>
        <!-- 数据隐私保护 -->
        <h2>ڈیٹا پرائیویسی کا تحفظ</h2>
        <p>
          پیسہ یار پر قرض کے لئے درخواست دیتے وقت، آپ کو اپنی کچھ ذاتی معلومات
          فراہم کرنے کی ضرورت ہوتی ہے. ہم آپ کو آپ کو گا رنٹی دیتے ہیں کہ جمع
          کردہ معلومات صرف آپ کو قرض دینے کے مقصد کے لئےاستعمال ہوتی ہے۔ جمع
          کردہ معلومات کا دائرہ آپ کو قرض فراہم کرنے کے لئے ضروری تفصیلات تک
          محدود ہے. آپ کی معلومات کو کسی دوسرے مقاصد کے لئے ظاہر یا استعمال نہیں
          کیا جائے گا۔
        </p>
        <!-- 特别提醒，请注意以下情况 -->
        <h2>
          خصوصی یاد دہانی، براہ کرم مندرجہ ذیل صورتحال اور مواقعات پر توجہ دیں
        </h2>
        <div class="lastContent">
          ہمارے ادائیگی کے طریقے پیسہ یار ایپ اور آفیشل ویب سائٹ پر دکھایا جاتے
          ہیں۔ فی الحال ہم صرف ایزی پیسہ اور جاز کیش کے ذریعے ادائیگی کرتے ہیں.
          ادائیگی کرتے وقت، براہ کرم کمپنی کے ذریعہ فراہم کردہ طریقہ کار پر عمل
          کرنے کو یقینی بنائیں
          <ul>
            <li>
              براہ کرم پیسہ یار ایپ کے ذریعے ادائیگی کوڈ حاصل کرنے کو یقینی
              بنائیں۔ ادائیگی کرتے وقت ایزی پیسہ اور جاز کیش پر پیسہ یار جیسے ہی
              سرچ کریں گے، پیسہ یار ایپ آپ کے سامنے آجائے گی اس کے بعد عمل کو
              مکمل کرنے کے لئے ادائیگی کا کوڈ درج کریں۔
            </li>
            <li>
              براہ کرم ادائیگی کے عمل کے دوران وصول کنندہ یعنی پیسیار کے اکاؤنٹ
              کی تصدیق کو یقینی بنائیں۔ براہ مہربانی غیر معروف اکاؤنٹس میں
              ادائیگی کی منتقلی سے کرنے سے اجتناب کریں!
            </li>
            <li>
              اگر کوئی آپ سے رابطہ کرتا ہے اور ذاتی ادائیگی اکاؤنٹ فراہم کرتا ہے
              تو، براہ کرم اعتماد نہ کریں یا کوئی معلومات فراہم نہ کریں۔ اگر آپ
              کو ایسی صورتحال کا سامنا کرنا سامنا کریں تو براہ کرم اسے فوراً
              ہمیں رپورٹ کریں۔ اگر آپ کی رپورٹ مخلصانہ اور کارگزار ثابت ہوئی، تو
              ہم آپ کو ایک مخصوص اضافی انعام سے نوازیں گے۔
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <FootUrdo />
  </div>
</template>

<script>
import HeaderUrdo from "@/components/headerUrdo.vue";
import FootUrdo from "@/components/footUrdo.vue";
export default {
  data() {
    return {
      phoneNum: "", // 电话号码
      verifyStatus: 2, // 判断状态
    };
  },
  components: {
    HeaderUrdo,
    FootUrdo,
  },
  watch: {
    phoneNum: function () {
      if (!this.phoneNum) {
        this.verifyStatus = 2;
      }
    },
  },
  mounted() {
    this.$sensors.track("v_notify_page", {
      // 传递的参数
      source_page: "进入号码查询页面Urdo", // 点击的页面
    });
  },
  methods: {
    // 判断电话
    verifyBtn() {
      if (this.phoneNum.length == 10) {
        if (
          (this.phoneNum.includes("0516011") &&
            Number(this.phoneNum.slice(7) >= 632) &&
            Number(this.phoneNum.slice(7) <= 932)) ||
          (this.phoneNum.includes("0518841") &&
            Number(this.phoneNum.slice(7) >= 270) &&
            Number(this.phoneNum.slice(7) <= 289))
        ) {
          this.verifyStatus = 1;
        } else {
          this.verifyStatus = 0;
        }
      } else if (
        this.phoneNum.length == 11 &&
        this.phoneNum.includes("03205690") &&
        this.phoneNum.slice(8) >= 243 &&
        this.phoneNum.slice(8) <= 272
      ) {
        this.verifyStatus = 1;
      } else if (
        this.phoneNum == "051111883883" ||
        this.phoneNum == "0518841270" ||
        this.phoneNum == "03088888260"
      ) {
        this.verifyStatus = 1;
      } else {
        this.verifyStatus = 0;
      }
      this.$sensors.track("c_notify_button", {
        // 传递的参数
        source_page: "点击查询号码Urdo", // 点击的页面
      });
    },
    // 限制输入数字
    changeIpt(val) {
      this.phoneNum = val.replace(/\D/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.numberVerify {
  width: 100%;
  height: 410px;
  background: url("../assets/image/service/banner.png") no-repeat;
  overflow: hidden;
  .contentBox {
    width: 864px;
    min-height: 292px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 24px;
    padding: 40px 32px;
    margin: 27px auto 0;
    .numIpt {
      position: relative;
      width: 582px;
      height: 68px;
      background: #ffffff;
      border-radius: 10px;
      border: 2px solid #999999;
      /deep/ .el-input__inner {
        height: 100%;
        border: none;
        text-align: right;
        border-radius: 10px;
        font-size: 20px;
      }
      /deep/ .el-input__suffix {
        position: absolute;
        left: 10px;
        top: 0;
      }
      /deep/ .el-input__icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
      }
    }
    .verifyBtn {
      width: 196px;
      height: 68px;
      // background: #b3b3b3;
      background-color: #2ea76a;
      border-radius: 10px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0;
      border: none;
    }
    .titleStatus {
      width: 100%;
      margin: 14px 0 40px;
      font-size: 24px;

      img {
        width: 32px;
        height: 32px;
        margin-left: 16px;
      }
      div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .phoneIntroduce {
      text-align: right;
      h3 {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .phoneBox {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        p {
          text-align: right;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333;
          line-height: 25px;
          margin: 10px 0 0 40px;
        }
      }

      .interval {
        p {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.introduce {
  width: 100%;
  margin-bottom: 100px;
  .introduceContent {
    width: 1200px;
    margin: 0 auto;
    text-align: right;
    h2 {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin: 40px 0 24px;
    }
    p {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .lastContent {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      ul {
        padding-right: 20px;
        li {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 33px;
          margin: 15px 0;
          span {
            font-size: 30px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
