<template>
  <div class="calculatorBox">
    <!-- tan Box -->
    <div class="tabBox">
      <div class="active" @click="changeTab(1)">
        Calculator
      </div>
      <div @click="changeTab(2)">
        Notify
      </div>
    </div>
    <!-- <h2>
      <img src="@/assets/newImage/service/moneyIcon.png" alt="" />
      <span>
        Loan Calculator
      </span>
      <img src="@/assets/newImage/service/moneyIcon.png" alt="" />
    </h2> -->
    <!-- option Box -->
    <div class="optionBox">
      <!-- left Box -->
      <div>
        <!-- amount -->
        <div class="amountOrday">
          <p>
            <span>Loan Amount</span>
            <span>Rs. {{ loanAmount }}</span>
          </p>
          <el-slider
            v-model="loanAmount"
            :min="1000"
            :max="50000"
            :step="100"
            :marks="amountMarks"
            :show-tooltip="false"
          ></el-slider>
        </div>
        <!-- days -->
        <div class="amountOrday secondAmountBox">
          <p>
            <span>Loan Days</span>
            <span>{{ loanDay }} Days</span>
          </p>
          <el-slider
            v-model="loanDay"
            :min="1"
            :max="90"
            :marks="dayMarks"
            :show-tooltip="false"
          ></el-slider>
        </div>
        <!-- total Amount -->
        <div class="totalAmount">
          <p>Total Repayment Amount</p>
          <div>Rs. {{ parseInt(totalAmount) }}</div>
        </div>
      </div>
      <!-- right Box -->
      <div>
        <ul>
          <li>
            <h5>Rate</h5>
            <div>
              <!-- <el-input
                placeholder="0 ~ 0.75"
                @input="changeIpt3"
                v-model="rate"
              ></el-input> -->
              <p class="nweNum">0.75</p>
              <span>%/day</span>
            </div>
          </li>
          <li style="margin-bottom: 15px">
            <h5>Penalty Rate</h5>
            <div>
              <!-- <el-input
                placeholder="0 ~ 2.2"
                @input="changeIpt4"
                v-model="penaltyRate"
              ></el-input> -->
              <p class="nweNum">2.3</p>
              <span>%/day</span>
            </div>
          </li>
          <li class="titleText">
            Penalty interest will only be added after the repayment date
            <p>
              If the repayment is not made on time, a daily penalty interest of
              0%-2.2% will be charged.
            </p>
          </li>
          <li>
            <h5>Overdue Days</h5>
            <div>
              <el-input @input="changeIpt5" v-model="overdueDays"></el-input>
              <span>days</span>
            </div>
          </li>
          <li>
            <h5>Markup</h5>
            <p>Rs. {{ parseInt(markupAmount) }}</p>
          </li>
          <li>
            <h5>Overdue Charge</h5>
            <p>Rs. {{ parseInt(overdueAmount) }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- submit button -->
    <div class="subBtn">
      <p>
        The interest and penalties mentioned are based on individual credit
        status.
      </p>
      <img @click="computBtn" src="@/assets/newImage/service/btn.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalAmount: 0, // 总还款金额
      markupAmount: 0, // 利息金额
      overdueAmount: 0, // 罚息金额
      // 计算机属性参数
      loanAmount: 1000, // 本金
      loanDay: 1, // 借款天数
      rate: 0.75, // 利率
      penaltyRate: 2.3, // 罚息
      overdueDays: 0, // 逾期天数
      amountMarks: {
        1000: "Rs. 1,000",
        50000: "Rs. 50,000",
      },
      dayMarks: {
        1: "1 days",
        90: "90 days",
      },
    };
  },
  methods: {
    // 计算
    computBtn() {
      if (!this.loanAmount) {
        return this.$message.error("Please enter the loan amount");
      } else if (this.loanDay === "") {
        return this.$message.error("Please enter the loan days");
      } else if (this.rate === "") {
        return this.$message.error("Please enter the interest rate (0-0.75%)");
      } else if (this.penaltyRate === "") {
        return this.$message.error(
          "Please enter the penalty interest (0-2.2%)"
        );
      } else if (this.overdueDays === "") {
        return this.$message.error("Please enter the overdue days");
      }
      // 利息金额
      this.markupAmount = this.loanAmount * (this.rate / 100) * this.loanDay;
      // 罚息金额
      this.overdueAmount =
        this.loanAmount * (this.penaltyRate / 100) * this.overdueDays;
      console.log(this.penaltyRate / 100, this.rate / 100);
      // 总还款金额
      this.totalAmount =
        Number(this.loanAmount) +
        Number(this.markupAmount) +
        Number(this.overdueAmount);
      this.$sensors.track("c_calculator_button", {
        // 传递的参数
        source_page: "点击计算", // 点击的页面
      });
    },

    // 利率限制
    changeIpt3(val) {
      // if (!val) {
      //   this.rate = 0;
      // }
      if (!Number(val) && Number(val) !== 0) {
        this.rate = val.slice(0, val.length - 1);
      }
      if (val.length > 4) {
        this.rate = val.slice(0, 4);
      }
      if (val == 0 && !val.includes(".") && val.length > 1) {
        this.rate = 0;
      }
      if (Number(val) > 0.75) {
        this.rate = 0.75;
      }
    },

    // 罚率限制
    changeIpt4(val) {
      // if (!val) {
      //   this.penaltyRate = 0;
      // }
      if (!Number(val) && Number(val) !== 0) {
        this.penaltyRate = val.slice(0, val.length - 1);
      }
      if (val.length > 4) {
        this.penaltyRate = val.slice(0, 4);
      }
      if (val == 0 && !val.includes(".") && val.length > 1) {
        this.penaltyRate = 0;
      }
      if (Number(val) > 2.3) {
        this.penaltyRate = 2.3;
      }
    },
    // 逾期天数限制
    changeIpt5(val) {
      this.overdueDays = val.replace(/\D/g, "");
    },
    changeTab(row) {
      this.$emit("changeTab", row);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculatorBox {
  width: 1440px;
  // height: 810px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 0px 20px 0px #eaecef,
    inset 0px 2px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  margin: 88px auto 0;
  overflow: hidden;
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #2c2c34;
    margin: 88px 0 72px;
    img {
      width: 60px;
      height: 34px;
    }
    span {
      margin: 0 16px;
    }
  }

  .optionBox {
    display: flex;
    & > div {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .amountOrday {
        width: 461px;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 32px;
            font-family: OpenSans-Bold, OpenSans;
            font-weight: bold;
            color: #333333;
            &:first-child {
              font-size: 18px;
              font-family: OpenSans-SemiBold, OpenSans;
              font-weight: 600;
              color: #333333;
            }
          }
        }
      }
      /deep/ .el-slider__runway {
        height: 8px;
        background: #d9f4dd;
        border-radius: 4px;
      }
      /deep/ .el-slider__button-wrapper {
        width: 18px;
        height: 18px;
        top: -65%;
      }
      /deep/ .el-slider__button {
        width: 100%;
        height: 100%;
        background: #2ea76a;
        border: none;
        display: block;
      }
      /deep/ .el-slider__bar {
        height: 8px;
        background: #2ea76a;
        border-radius: 4px;
      }
      /deep/ .el-slider__marks-stop {
        display: none;
      }
    }

    .totalAmount {
      text-align: center;
      p {
        font-size: 18px;
        font-family: OpenSans-SemiBold, OpenSans;
        font-weight: 600;
        color: #333333;
      }
      div {
        font-size: 42px;
        font-family: OpenSans-Bold, OpenSans;
        font-weight: bold;
        color: #2ea76a;
        margin-top: 15px;
      }
    }

    ul {
      min-width: 430px;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        h5 {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        div {
          display: flex;
          align-items: center;
          span {
            text-align: right;
            width: 90px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .nweNum {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2ea76a;
          }
        }
        p {
          font-size: 32px;
          font-family: OpenSans-Bold, OpenSans;
          font-weight: bold;
          color: #333333;
        }

        /deep/ .el-input {
          width: 135px;
          height: 42px;
          border-radius: 8px;
          border: 2px solid #999999;
        }
        /deep/ .el-input__inner {
          border: none;
          height: 100%;
          border-radius: 8px;
          font-size: 18px;
          color: #2ea76a;
          text-align: right;
        }
      }
    }
  }

  .subBtn {
    text-align: center;
    margin-top: 53px;
    p {
      font-size: 14px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #7c7c7c;
      margin-bottom: 20px;
    }
    img {
      cursor: pointer;
      width: 378px;
      height: 120px;
      display: inline-block;
    }
  }
}
/deep/ .el-slider__marks-text {
  width: 70px;
}

.titleText {
  display: block !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bcbcbc;
  margin-bottom: 20px;
  p {
    font-size: 14px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400 !important;
    color: #bcbcbc !important;
    width: 477px;
    word-break: break-all;
  }
}

.secondAmountBox {
  margin: 50px 0 35px;
}

.tabBox {
  display: flex;
  width: 1240px;
  height: 56px;
  background: #f5f6f9;
  border-radius: 28px;
  overflow: hidden;
  margin: 63px auto 72px;
  & > div {
    cursor: pointer;
    width: 50%;
    text-align: center;
    font-size: 22px;
    font-family: OpenSans-Regular, OpenSans;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
  }
  .active {
    background: #2ea76a;
    border-radius: 28px;
    font-size: 22px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #fff;
    border-radius: 28px;
  }
}
</style>
