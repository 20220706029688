<template>
  <div>
    <Header activeNav="Notify" />
    <!-- banner 大盒子 -->
    <div class="numberVerify">
      <!-- 计算内容 -->
      <div class="contentBox">
        <!-- 输入框 -->
        <div style="display: flex; justify-content: space-between">
          <el-input
            class="numIpt"
            @input="changeIpt"
            maxlength="12"
            v-model="phoneNum"
            clearable
          ></el-input>
          <el-button
            :disabled="phoneNum ? false : true"
            :style="{ background: phoneNum ? '#2ea76a' : '#b3b3b3' }"
            class="verifyBtn"
            @click="verifyBtn"
            >Inquiry number</el-button
          >
        </div>
        <div class="titleStatus">
          <div v-show="verifyStatus == 1">
            <img src="../assets/image/service/success.png" alt="" />
            <span style="color: #2ea76a"> Officially verified number </span>
          </div>
          <div v-show="verifyStatus == 0">
            <img src="../assets/image/service/error.png" alt="" />
            <span style="color: #e73400"> Unofficially Verified Number </span>
          </div>
        </div>
        <!-- 电话介绍 -->
        <div class="phoneIntroduce">
          <h3>Official Phone Number</h3>
          <div class="phoneBox interval">
            <p>0516011632 - 0516011932</p>
            <p>0518841270 - 0518841289</p>
            <p>03205690243 - 03205690272</p>
          </div>
          <div class="phoneBox">
            <p>03088888260</p>
            <p>051111883883</p>
            <p>0518841270</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 介绍文案 -->
    <div class="introduce">
      <div class="introduceContent">
        <!-- 持牌运营机构 -->
        <h2>Under Licensed Operating Institution</h2>
        <p>
          Paisayaar is an online loan application registered with SECP and
          certified with NBFC, through which you can obtain secure and flexible
          loans. All loan expenses are openly transparent, with no hidden
          charges.
        </p>
        <!-- 数据隐私保护 -->
        <h2>Data Privacy Protection</h2>
        <p>
          When applying for a loan on Paisayaar, you need to provide some of
          your personal information. We assure you that the collected
          information is solely for the purpose of granting you the loan. The
          scope of collected information is limited to the necessary details
          required to provide you with the loan. Your information will not be
          disclosed or used for any other purposes.
        </p>
        <!-- 特别提醒，请注意以下情况 -->
        <h2>
          Special reminder, please pay attention to the following situations
        </h2>
        <div class="lastContent">
          Our repayment methods will be displayed within the official Paisayaar
          App and the official website. Currently, we only support repayment
          through Easypaisa and JazzCash. When making repayments, please ensure
          to follow the method provided by the company:
          <ul>
            <li>
              <span>·</span> Please ensure to obtain the repayment code through
              the Paisayaar App. When making the repayment, search for
              【Paisayaar】 on Easypaisa and JazzCash. You will see our
              application. Enter the repayment code to complete the process.
            </li>
            <li>
              <span>·</span> Please ensure to verify the recipient account
              as【Paisayaar】during the repayment process. Kindly avoid
              transferring payments to unfamiliar accounts!
            </li>
            <li>
              <span>·</span> If someone contacts you and provides a personal
              repayment account, please do not trust or provide any information.
              If you encounter such a situation, please promptly report it to
              us. If your feedback is genuine and effective, we will reward you
              with a certain additional incentive.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/foot.vue";
export default {
  data() {
    return {
      phoneNum: "", // 电话号码
      verifyStatus: 2, // 判断状态
    };
  },
  components: {
    Header,
    Footer,
  },
  watch: {
    phoneNum: function () {
      if (!this.phoneNum) {
        this.verifyStatus = 2;
      }
    },
  },
  mounted() {
    this.$sensors.track("v_notify_page", {
      // 传递的参数
      source_page: "进入号码查询页面", // 点击的页面
    });
  },
  methods: {
    // 判断电话
    verifyBtn() {
      if (this.phoneNum.length == 10) {
        if (
          (this.phoneNum.includes("0516011") &&
            Number(this.phoneNum.slice(7) >= 632) &&
            Number(this.phoneNum.slice(7) <= 932)) ||
          (this.phoneNum.includes("0518841") &&
            Number(this.phoneNum.slice(7) >= 270) &&
            Number(this.phoneNum.slice(7) <= 289))
        ) {
          this.verifyStatus = 1;
        } else {
          this.verifyStatus = 0;
        }
      } else if (
        this.phoneNum.length == 11 &&
        this.phoneNum.includes("03205690") &&
        this.phoneNum.slice(8) >= 243 &&
        this.phoneNum.slice(8) <= 272
      ) {
        this.verifyStatus = 1;
      } else if (
        this.phoneNum == "051111883883" ||
        this.phoneNum == "0518841270" ||
        this.phoneNum == "03088888260"
      ) {
        this.verifyStatus = 1;
      } else {
        this.verifyStatus = 0;
      }
      this.$sensors.track("c_notify_button", {
        // 传递的参数
        source_page: "点击查询号码", // 点击的页面
      });
    },
    // 限制输入数字
    changeIpt(val) {
      this.phoneNum = val.replace(/\D/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.numberVerify {
  width: 100%;
  height: 410px;
  background: url("../assets/image/service/banner.png") no-repeat;
  overflow: hidden;
  .contentBox {
    width: 864px;
    min-height: 292px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 24px;
    padding: 40px 32px;
    margin: 27px auto 0;
    .numIpt {
      width: 582px;
      height: 68px;
      background: #ffffff;
      border-radius: 10px;
      border: 2px solid #999999;
      /deep/ .el-input__inner {
        height: 100%;
        border: none;
        border-radius: 10px;
        font-size: 20px;
      }
      /deep/ .el-input__icon {
        font-size: 20px;
      }
    }
    .verifyBtn {
      width: 196px;
      height: 68px;
      // background: #b3b3b3;
      background-color: #2ea76a;
      border-radius: 10px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0;
      border: none;
    }
    .titleStatus {
      width: 100%;
      // height: 50px;
      margin: 14px 0 40px;
      font-size: 24px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
      div {
        display: flex;
        align-items: center;
      }
    }
    .phoneIntroduce {
      h3 {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .phoneBox {
        display: flex;
        flex-wrap: wrap;
        p {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333;
          line-height: 25px;
          margin: 10px 40px 0 0;
        }
      }

      .interval {
        p {
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.introduce {
  width: 100%;
  margin-bottom: 100px;
  .introduceContent {
    width: 1200px;
    margin: 0 auto;
    h2 {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin: 40px 0 24px;
    }
    p {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .lastContent {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      ul {
        padding-left: 20px;
        li {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 33px;
          margin: 15px 0;
          span {
            font-size: 30px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
