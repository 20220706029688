<template>
  <div>
    <Header class="header" />
    <!-- banner -->
    <div class="bannerBox">
      <a
        href="https://play.google.com/store/apps/details?id=com.personale.credit.carry.cash.loan.paisaya"
        target="blank"
      >
        <img
          src="../../assets/newImage/home/homeGoogle.png"
          alt=""
          class="google"
        />
      </a>
    </div>
    <!-- <el-carousel indicator-position="outside" :interval="3000">
      <el-carousel-item>
      </el-carousel-item>
      <el-carousel-item>
        <img
          style="width:100%"
          src="../../assets/newImage/home/seiperBanner.png"
          alt=""
        />
      </el-carousel-item>
      <el-carousel-item>
        <div class="bannerBox">
          <a
            href="https://play.google.com/store/apps/details?id=com.personale.credit.carry.cash.loan.paisaya"
            target="blank"
          >
            <img
              src="../../assets/newImage/home/homeGoogle.png"
              alt=""
              class="google"
            />
          </a>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img
          style="width:100%"
          src="../../assets/newImage/home/seiperBanner.png"
          alt=""
        />
      </el-carousel-item>
    </el-carousel> -->
    <!-- content -->
    <div class="flowBox">
      <img
        class="flowPathBox"
        src="../../assets/newImage/home/flowPathBox.png"
        alt=""
      />
      <h3>How to borrow money in Paisayaar?</h3>
      <img
        class="progressImg"
        src="../../assets/newImage/home/progress.png"
        alt=""
      />

      <!-- swiper box -->
      <el-carousel trigger="click" style="margin-bottom: 81px" arrow="always">
        <el-carousel-item v-for="item in imgList" :key="item">
          <img :src="item" alt="" style="width:100%;" />
        </el-carousel-item>
      </el-carousel>
      <!-- <img
        src="../../assets/newImage/home/swiperEn.png"
        alt=""
        style="margin-bottom: 81px; width:100%;"
      /> -->

      <!-- import Content -->
      <div class="importBox">
        <h2>Important Announcement</h2>
        <h5>Paisayaar values the safety of its customers.</h5>
        <p>
          If Paisayaar users are subject to abusive language, threats to privacy
          and safety, etc. by any of its staff members, you are encouraged to
          please keep evidence and file a complaint to us.Such actions shall not
          be tolerated by Paisayaar.
        </p>
        <p>
          The loan granted by Paisayaar is subject to your confirmation. You may
          click on the “Quit” button during your loan application process.
          Paisayaar will never disburse the loan without your consent. If you
          have applied for the loan by mistake, please repay the principal
          amount within 24 hours and contact us at your earliest convenience.
          Subject to our internal policies, we shall cancel your loan
          application free of charge. For any queries, please do not hesitate to
          contact us, we will serve you Whole-heartedly.
        </p>
        <p style="margin-bottom: 10px">
          Service Hotline: <span>051-111-883-883</span>
        </p>
        <p style="margin-bottom: 10px">
          Complaint Email: <span>support@paisayaar.pk</span>
        </p>
        <p style="margin-bottom: 30px">
          Service Timing: <span>9:00-18:00</span>
        </p>
        <p style="margin-bottom: 0">
          Paisayaar reserves the right of final interpretation for all the rules
          above.
        </p>
      </div>
      <!-- 中部banner -->
      <img
        class="centerBanner"
        src="../../assets/newImage/home/centerBanner.png"
        alt=""
      />
      <!-- about Us -->
      <div class="contentBox">
        <h2>
          <img src="../../assets/newImage/home/aboutIcon.png" alt="" />
          About Us
        </h2>
        <p>
          Paisayaar belongs to the JINGLECRED DIGITAL FINANCE LIMITED is an
          online loan application. You can get safe and flexible loans through
          Paisayaar. You can choose a variety of methods to receive the loan
          amount. You can also pay your loan through multiple channels and there
          are no hidden charges. Your information is secure with us and used for
          verification only. We will determine the loan amount through the risk
          assessment of your information and credit evaluation.
        </p>
      </div>

      <!-- vision Mission -->
      <div class="contentBox" style="background: #F8FDFC;">
        <h2>
          <img src="../../assets/newImage/home/visionIcon.png" alt="" />
          Vision & Mission
        </h2>
        <p>
          To open the inclusion of short-term digital credit to the unbanked &
          general population in Pakistan.
          <br />
          To allow customers currently not being served by traditional banks to
          become active in the micro credit ecosystem and enable them to build a
          credit identity; in <br />
          an easy, trustworthy & fast manner.
        </p>
      </div>

      <!-- Information Disclosu -->
      <div class="contentBox">
        <h2>
          <img src="../../assets/newImage/home/inforIcon.png" alt="" />
          Information Disclosure
        </h2>
        <p>
          Company name：JINGLECRED DIGITAL FINANCE LIMITED
        </p>
        <p>
          Principal Business: Nano Lendings
        </p>
        <p>
          Status of company: NBFC (Investment Finance Service)
        </p>
        <p>
          License No. SECP/LRD/78/JDFL/2022-85
        </p>
        <!-- <p>
          Date of License issued: March 10, 2023
        </p> -->
      </div>
    </div>
    <Footer />
    <TitleDialog @close="close" :status="'en'" :visible.sync="titleVisible" />
  </div>
</template>

<script>
import Header from "../../components/newHeader.vue";
import Footer from "../../components/newFooter.vue";
import TitleDialog from "../../components/titleDialog.vue";
export default {
  components: {
    Header,
    Footer,
    TitleDialog,
  },
  data() {
    return {
      titleVisible: false,
      imgList: [
        require("../../assets/newImage/home/swiperEn.png"),
        require("../../assets/newImage/home/swiperEn1.png"),
        require("../../assets/newImage/home/swiperEn2.png"),
      ],
    };
  },
  mounted() {
    if (!sessionStorage.getItem("newTime")) {
      sessionStorage.setItem("newTime", new Date());
      this.titleVisible = true;
      // this.$sensors.track("v_web_page", {
      //   // 传递的参数
      //   source_page: "首页", // 点击的页面
      // });
    }
    if (
      document.body.clientWeight <= 750 ||
      document.documentElement.clientWidth <= 750
    ) {
      this.titleVisible = false;
    }
  },

  methods: {
    close() {
      this.titleVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  display: inline-block;
}
.header {
  // position: absolute;
  // left: 0;
  // top: 0;
  // z-index: 20;
  // background-color: #fff;
  background: linear-gradient(
    90deg,
    #ebfaf6 0%,
    #e9faf5 5%,
    #e4f9f3 7%,
    #f6fcfb 20%,
    #f8fdfc 50%,
    #f6fdfb 100%
  );
}
.el-carousel {
  height: 740px;
  /deep/ .el-carousel__container {
    height: 100% !important;
  }
}
.bannerBox {
  position: relative;
  width: 100%;
  height: 740px;
  background: url("../../assets/newImage/home/banner.png") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    left: 240px;
    top: 475px;
  }
}
.flowBox {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: -60px;
  z-index: 10;
  background-color: #fff;
  border-radius: 56px 56px 0px 0px;
  h3 {
    font-size: 36px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #2c2c34;
  }
  .importBox {
    width: 1441px;
    height: 763px;
    background: url("../../assets/newImage/home/importBgc.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 72px;
    margin-bottom: 75px;
    h2 {
      font-size: 36px;
      font-family: OpenSans-Bold, OpenSans;
      font-weight: bold;
      color: #2c2c34;
      margin-bottom: 8px;
    }
    h5 {
      font-size: 22px;
      font-family: OpenSans-SemiBold, OpenSans;
      font-weight: 600;
      color: #2ea76a;
      margin-bottom: 56px;
    }
    p {
      text-align: left;
      font-size: 20px;
      width: 1201px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #666666;
      margin: 0 auto;
      margin-bottom: 40px;
      line-height: 36px;
      span {
        color: #2ea76a;
        font-weight: 700;
      }
    }
  }

  .contentBox {
    padding: 88px 0 130px;
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-family: OpenSans-Bold, OpenSans;
      font-weight: bold;
      color: #2c2c34;
      margin-bottom: 32px;
      img {
        width: 54px;
        height: 54px;
        margin: 0;
        margin-right: 16px;
      }
    }
    p {
      width: 1400px;
      font-size: 18px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #666666;
      margin: 0 auto;
      line-height: 36px;
    }
  }
}

.google {
  width: 185px;
  height: 55px;
}

.flowPathBox {
  width: 1312px;
  height: 168px;
  margin-top: -83px;
  margin-bottom: 105px;
}

.progressImg {
  width: 879px;
  margin: 74px 0 185px;
}
.centerBanner {
  width: 100%;
  margin-bottom: 85px;
}
/deep/ .el-carousel__arrow {
  width: 50px;
  height: 50px;
  font-size: 26px;
  background-color: rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
/deep/ .el-carousel__container {
  height: 3.2rem;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 20px;
}
.el-carousel--horizontal {
  overflow: hidden;
}
</style>
