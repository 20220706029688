<template>
  <div class="headBox">
    <!-- left right LOGO -->
    <div style="display:flex">
      <img
        v-show="language == 'En'"
        class="enHeaderImg"
        src="../assets/newImage/head/leftLogo.png"
        alt=""
      />

      <img
        v-show="language !== 'En'"
        class="urdoHeaderImg"
        src="../assets/newImage/head/rightLogo.png"
        alt=""
      />
    </div>

    <!-- nav List -->
    <div
      class="rightBox"
      :class="language == 'En' ? 'rightClass' : ''"
      :style="{
        marginLeft: language == 'En' ? '27%' : '0',
      }"
    >
      <el-switch
        v-show="language !== 'En'"
        style="margin-right: 0"
        :class="[language == 'En' ? 'enStyle' : 'urdoStyle']"
        :width="65"
        v-model="switchData"
        @change="handleCommand"
      >
      </el-switch>
      <ul :style="{ marginRight: language == 'En' ? '0' : '31%' }">
        <li
          @click="changeRouter(item)"
          v-for="(item, index) in navList"
          :key="index"
          :class="[
            item.path == $route.path || item.pathUrdo == $route.path
              ? 'active'
              : '',
          ]"
        >
          {{ language == "En" ? item.name : item.nameUrdo }}
        </li>
      </ul>
      <el-switch
        v-show="language == 'En'"
        style="margin-right: 0"
        :class="[language == 'En' ? 'enStyle' : 'urdoStyle']"
        :width="65"
        v-model="switchData"
        @change="handleCommand"
      >
      </el-switch>
      <!-- left right LOGO -->
      <div style="display:flex">
        <img
          v-show="language == 'En'"
          class="urdoHeaderImg"
          style=" margin-left: 20%"
          src="../assets/newImage/head/rightLogo.png"
          alt=""
        />
        <img
          v-show="language !== 'En'"
          class="enHeaderImg"
          src="../assets/newImage/head/leftLogo.png"
          alt=""
        />
      </div>

      <!-- <div v-show="typeData == 'faq'" style="display:flex">
        <img
          v-show="language == 'En'"
          src="../assets/newImage/head/faqhead2.png"
          class="urdoHeaderImg"
          style="margin-left: 20%"
          alt=""
        />

        <img
          v-show="language !== 'En'"
          class="enHeaderImg"
          src="../assets/newImage/head/faqHead.png"
          alt=""
        />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // typeData: {
    //   type: String,
    // },
  },
  data() {
    return {
      navList: [
        {
          name: "Home",
          nameUrdo: "ہوم پیج",
          path: "/home",
          pathUrdo: "/homeUrdo",
        },
        {
          name: "Service",
          nameUrdo: "سروس",
          path: "/servise",
          pathUrdo: "/serviseUrdo",
        },
        {
          name: "FAQs",
          nameUrdo: "عمومی سوالات",
          path: "/faq",
          pathUrdo: "/faqUrdo",
        },
        {
          name: "Complaints",
          nameUrdo: "شکایت",
          path: "/complaints",
          pathUrdo: "/complaintsUrdo",
        },
      ],
      language: "En",
      switchData: true,
    };
  },
  mounted() {
    if (sessionStorage.getItem("language")) {
      this.language = sessionStorage.getItem("language");
    }
    if (this.language == "En" && !this.$route.path.includes("Urdo")) {
      this.language = "En";
      this.switchData = true;
    } else {
      this.language = "اردو";
      this.switchData = false;
    }
  },

  watch: {
    language: function() {
      this.navList.reverse();
    },
  },

  methods: {
    changeRouter(row) {
      if (row.path == this.$route.path || row.pathUrdo == this.$route.path)
        return;
      if (this.language == "En") {
        this.$router.push(row.path);
      } else {
        this.$router.push(row.pathUrdo);
      }
    },
    handleCommand(command) {
      let that = this;
      if (command) {
        sessionStorage.setItem("language", "En");
        this.language = "En";
        this.$router.push(that.$route.path.slice(0, -4));
      } else {
        sessionStorage.setItem("language", "اردو");
        this.language = "اردو";
        this.$router.push(that.$route.path + "Urdo");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.headBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding-left: 240px;
  .rightBox {
    width: calc(100% - 451px);
    display: flex;
    align-items: center;
    ul {
      display: flex;
      li {
        cursor: pointer;
        font-size: 18px;
        font-family: OpenSans-Regular, OpenSans;
        font-weight: 400;
        color: #333333;
        width: 140px;
        text-align: center;
        //   margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          font-weight: 700;
        }
      }
      .active {
        font-weight: 700;
      }
    }
    .typeFAQBox {
      // li {
      //   color: #fff;
      // }
    }
    // .language {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   cursor: pointer;
    //   min-width: 62px;
    //   height: 26px;
    //   border-radius: 14px;
    //   border: 1px solid #b3b3b3;
    //   margin: 0 50px 0 42px;
    //   font-size: 14px;
    //   font-family: OpenSans-Bold, OpenSans;
    //   font-weight: bold;
    //   color: #333333;
    //   div {
    //     margin: 0 10px;
    //   }
    //   img {
    //     width: 26px;
    //     height: 26px;
    //   }
    // }
  }
}
/deep/ .el-switch {
  margin: 0 15px;
}
/deep/ .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  span {
    width: 25px;
    margin-right: 10px;
    font-size: 16px;
  }
  img {
    width: 26px;
    height: 26px;
  }
}
.menuActive {
  background-color: #ecf5ff;
  color: #66b1ff;
}

/deep/ .el-switch__core {
  width: 65px !important;
  border-radius: 14px;
  height: 27px;
  border: 1px solid #b3b3b3;
  background: #fff;
}

.enStyle {
  /deep/ .el-switch__core::after {
    width: 23px;
    height: 23px;
    background: url("../assets/newImage/head/lanEn.png") no-repeat;
    background-size: 100% 100%;
  }
}
.urdoStyle {
  /deep/ .el-switch__core::after {
    width: 23px;
    height: 23px;
    background: url("../assets/newImage/head/lanUrdo.png") no-repeat;
    background-size: 100% 100%;
  }
}

/deep/ .el-switch__core::before {
  content: "اردو";
  font-size: 14px;
  font-family: OpenSans-Bold, OpenSans;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  margin-left: 27px;
}

/deep/ .el-switch.is-checked .el-switch__core::after {
  margin-left: -24px;
}

/deep/ .el-switch.is-checked .el-switch__core::before {
  content: "En";
  font-size: 14px;
  font-family: OpenSans-Bold, OpenSans;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
  margin-left: 15px;
}

/deep/ .el-switch.is-checked .el-switch__core {
  border: 1px solid #b3b3b3;
  background: #fff;
}
.enHeaderImg {
  width: 211px;
  height: 48px;
}
.urdoHeaderImg {
  width: 161px;
  height: 43px;
}
</style>
