<template>
  <div class="about">
    <header-urdo activeNav="aboutUrdo"></header-urdo>
    <!-- <img src="../assets/image/about/bannerUrdo.png" alt="" class="banner" /> -->
    <div class="banner"></div>
    <div class="wrapper width_common">
      <h1 style="justify-content: center">
        ہمارے بارے میں
        <div class="icon"></div>
      </h1>
      <p style="margin-bottom: 114px">
        پیسہ یار کا تعلق جینگل کریڈ ڈیجیٹل فائنانس لمیٹڈ سے ہے۔ پیسہ یار کے
        ذریعے آپ محفوظ اور آسان قرض حاصل کر سکتے ہیں۔اب قرض کی رقم وصول کرنے کے
        لیے مختلف طریقوں کا انتخاب کر سکتے ہیں۔ قرض کی ادائیگی متعدد چینلز کے
        ذریعے بھی کی جا سکتی ہے اور اس میں کوئی پوشیدہ چارجز نہیں ہیں۔ ہم آپ کو
        یقین دہانی کراتے ہیں کے آپ کی معلومات ہمارے پاس محفوظ ہیں اور آپ کی
        معلومات صرف تصدیق کے مقاصد کے لیے استعمال ہوتی ہیں۔ہم آپ کی دی گئی
        معلومات اور کریڈ ہسٹری کی بنیاد پر قرض کی رقم کا تعین کرتے ہیں۔

        <br />
        <br />
        پروڈکٹ کی معلومات: قرض کی رقم 1,000-50,000 کے درمیان ہے اور قرض کی زیادہ
        سے زیادہ مدت 90 دن ہے۔ یومیہ شرح سود 0%-0.75% کے درمیان ہے۔ اگر ادائیگی
        وقت پر نہیں کی جاتی ہے، تو یومیہ 2% سے 2.5% کا جرمانہ سود وصول کیا جائے
        گا۔
      </p>
    </div>
    <div class="display">
      <img src="../assets/image/about/one.png" alt="" />

      <div class="left">
        <h1 style="margin-left: 30px">
          وژن اور مشن
          <div class="icon"></div>
        </h1>
        <p style="margin-bottom: 30px">
          پاکستان میں بینکوں سے محروم اور عام آبادی کے لیے قلیل مدتی ڈیجیٹل
          کریڈٹ کی شمولیت کو کھولنا۔
        </p>
        <p style="margin-bottom: 30px">
          ایک آسان، قابل اعتماد اور تیز انداز میں روایتی بینکوں کی طرف سے فی
          الحال خدمت نہ حاصل کرنے والے صارفین کو مائیکرو کریڈٹ ایکو سسٹم میں
          فعال ہونے کی اجازت دینا اور انہیں کریڈٹ شناخت بنانے کے قابل بنانا۔
        </p>
      </div>
    </div>
    <div class="wrapper width_common">
      <h1 style="justify-content: center">
        معلومات کا انکشاف
        <div class="icon" style="margin-right: 21px"></div>
      </h1>
      <div class="info">
        <p>JINGLECRED DIGITAL FINANCE LIMITED：کمپنی کا نام</p>
        <p>پرنسپل بزنس: نینو لونز</p>
        <p>(انوسٹمنٹ فنانس سروس) NBFC：کمپنی کا اسٹیٹس</p>
        <p>SECP/LRD/78/JDFL/2022-85：ائسنس نمبر</p>
        <p style="margin-bottom: 20px">
          لائسنس جاری ہونے کی تاریخ: 10 مارچ 2023
        </p>
        <p>
          (+9251) 6011632 - 6011932 (+9251) 8841270 - 8841289: آؤٹ بورڈ کال نمبر
        </p>
      </div>
    </div>
    <foot-urdo></foot-urdo>
  </div>
</template>

<script>
import headerUrdo from "@/components/headerUrdo.vue";
import footUrdo from "@/components/footUrdo.vue";

export default {
  name: "aboutUrdo",
  data() {
    return {};
  },
  components: {
    headerUrdo,
    footUrdo,
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.about {
  p {
    text-align: right;
  }
  .banner {
    width: 100%;
    height: 585px;
    background: url("../assets/image/about/bannerUrdo.png") no-repeat;
    background-size: 100%;
  }
  h1 {
    font-size: 36px;
    font-family: Roboto-Medium, Roboto;
    color: #333333;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
  }
  .icon {
    width: 28px;
    height: 28px;
    background: #2ea76a;
    margin-left: 21px;
    transform: rotate(45deg);
  }
  p {
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    color: #666666;
    line-height: 36px;
  }
  .wrapper {
    padding-top: 70px;
    box-sizing: border-box;
  }
  .info {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: 50%;
    transform: translate(-50%);
    text-align: right;
  }
  .display {
    display: flex;
    align-items: center;
    .left {
      width: 650px;
      h1 {
        margin-right: 30px;
        justify-content: end;
      }
    }
    img {
      width: 902px;
      transform: rotateY(180deg);
    }
  }
}

@media only screen and (max-width: 750px) {
  html {
    font-size: 100px;
  }
  .width_common {
    margin: 0 0.32rem;
    width: calc(100% - 0.7rem);
    .icon {
      margin-right: 0 !important;
    }
  }
  .about {
    .banner {
      width: 100%;
      height: 3.1rem;
      background: url("../assets/image/about/bannerUrdo.png") no-repeat;
      background-size: 100%;
    }
    h1 {
      font-size: 0.5rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #333333;
      margin-bottom: 0.16rem;
      display: flex;
      align-items: center;
      margin-right: 0 !important;
      justify-content: flex-end !important;
    }
    .icon {
      width: 0.28rem;
      height: 0.28rem;
      background: #2ea76a;
      margin-right: 0.21rem;
      transform: rotate(45deg);
    }
    p {
      font-size: 0.4rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 0.6rem;
      margin-bottom: 0.28rem !important;
    }
    .wrapper {
      padding-top: 0.23rem;
      box-sizing: border-box;
      & > div {
        margin-bottom: 0.28rem !important;
        transform: translate(0) !important;
        padding-left: 0 !important;
      }
    }
    .display {
      // display: flex;
      // align-items: center;
      padding: 0 0.32rem;
      .left {
        width: 100%;
        h1 {
          font-size: 0.5rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          margin-bottom: 0.16rem;
          margin-right: 0 !important;
        }
      }
      img {
        display: none;
      }
    }
  }
}
</style>
