<template>
  <div class="footBox">
    <!-- left Box -->
    <div class="leftBox">
      <div>
        <!-- image -->
        <div class="imgBox">
          <img src="../assets/newImage/foot/logo1.png" alt="" />
          <img src="../assets/image/logo_jingOther.png" alt="" />
        </div>
        <!-- divider -->
        <div class="divider"></div>
        <!-- title -->
        <h2>How To Contact Us</h2>
        <div class="contactList">
          <!-- Contact  list -->
          <div class="listBox firstList">
            <img src="../assets/newImage/foot/icon1.png" alt="" />
            <div>
              <p>051-111-883-883 (Service Hotline)</p>
              <p style="margin-bottom: 16px">051-884-127-0 (Service Hotline)</p>
              <p>03088889823 Grievance Redressal Officer</p>
            </div>
          </div>
          <div class="listBox">
            <img src="../assets/newImage/foot/icon2.png" alt="" />
            <p>support@paisayaar.pk</p>
          </div>
          <div class="listBox">
            <img src="../assets/newImage/foot/icon3.png" alt="" />
            <p>Monday - Sunday 09:00 - 18:00</p>
          </div>
          <div class="positionBox">
            <img src="../assets/newImage/foot/icon4.png" alt="" />
            <p>
              Apartment #3, 2nd Floor, Jispal Plaza, Plot #5, Sector G-8/4,
              Islamabad.
            </p>
          </div>
        </div>
        <div class="linkBox">
          <div class="lastFootBox">
            <!-- google map -->
            <img
              class="lastImg"
              src="../assets/newImage/foot/lastImg.png"
              alt=""
            />
            <!-- link List -->
            <p class="linkListBox">
              Legal：
              <a
                :href="
                  getSessions() == 'En' || !getSessions()
                    ? 'https://www.paisayaar.pk/h5/use.html'
                    : 'https://www.paisayaar.pk/h5/useUrdo.html'
                "
                >Terms & Conditions</a
              >
              <a
                :href="
                  getSessions() == 'En' || !getSessions()
                    ? 'https://www.paisayaar.pk/h5/privacy.html'
                    : 'https://www.paisayaar.pk/h5/privacyUrdo.html'
                "
                >Privacy Policy</a
              >
              <a
                :href="
                  getSessions() == 'En' || !getSessions()
                    ? 'https://www.paisayaar.pk/h5/userdata.html'
                    : 'https://www.paisayaar.pk/h5/userdataUrdo.html'
                "
                >User Data Policy</a
              >
            </p>
          </div>
          <div style="margin-right: 88px">
            <!-- follow Us -->
            <h3>Follow us</h3>
            <div class="threeLink">
              <a
                target="_blank"
                href="https://www.facebook.com/people/PaisayaarPersonal-online-loan/100091928599507/"
              >
                <img src="../assets/image/faceBookIcon.png" alt="" />
              </a>

              <a
                target="_blank"
                href="https://www.instagram.com/paisayaarofficial/"
              >
                <img src="../assets/image/photo.png" alt="" />
              </a>

              <a target="_blank" href="https://www.tiktok.com/@paisayaarloan">
                <img src="../assets/image/tikTok.png" alt="" />
              </a>

              <a
                target="_blank"
                href="https://www.youtube.com/@Paisayaar-Personalonlineloan"
              >
                <img src="../assets/image/youtubeIcon.png" alt="" />
              </a>

              <a target="_blank" href="https://twitter.com/Paisayaar">
                <img src="../assets/image/titerIcon.png" alt="" />
              </a>
            </div>
          </div>
          <!-- right box -->
          <div class="rightBox">
            <div>
              <img
                @click="openGoogel"
                class="googelBtn"
                src="../assets/newImage/foot/googleBtn.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: "En",
    };
  },
  methods: {
    openGoogel() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.personale.credit.carry.cash.loan.paisaya"
      );
    },
    getSessions() {
      return sessionStorage.getItem("language");
    },
  },
};
</script>

<style lang="scss" scoped>
.footBox {
  display: flex;
  width: 100%;
  // height: 590px;
  overflow: hidden;
  background: url("../assets/newImage/foot/footBgc.png") no-repeat;
  background-size: 100% 100%;
}
.leftBox {
  margin-left: 240px;
  .imgBox {
    display: flex;
    padding-top: 40px;
    img {
      width: 211px;
      height: 49px;
      margin-right: 56px;
      &:last-child {
        width: 172px;
        height: 49px;
      }
    }
  }
  .divider {
    width: 106px;
    height: 4px;
    background: #2ea76a;
    border-radius: 2px;
    margin: 32px 0 24px;
  }
  .contactList {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    & > div {
      margin-right: 88px;
    }
  }
  h2 {
    font-size: 22px;
    font-family: OpenSans-Regular, OpenSans;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 21px;
  }

  .listBox {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 16px;
      vertical-align: middle;
    }
    p {
      font-size: 18px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .positionBox {
    display: flex;
    img {
      width: 18px;
      height: 19px;
      margin-right: 16px;
    }
    p {
      width: 245px;
      height: 96px;
      font-size: 18px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .firstList {
    align-items: baseline;
    p {
      margin-bottom: 10px;
    }
  }

  h3 {
    font-size: 22px;
    font-family: OpenSans-Regular, OpenSans;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 33px;
  }

  .linkBox {
    display: flex;
    margin-top: 50px;
    .lastFootBox {
      margin-right: 91px;
    }
  }

  .threeLink {
    display: flex;
    img {
      cursor: pointer;
      width: 44px;
      height: 44px;
      margin-right: 32px;
      display: block;
    }
  }

  .linkListBox {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2ea76a;
    margin-top: 33px;
    a {
      color: #2ea76a;
      text-decoration: underline;
      margin: 0 8px;
    }
  }
}
.rightBox {
  // padding-top: 79px;

  #GoogleBox {
    width: 328px;
    height: 190px;
    border-radius: 20px;
    margin-top: 24px;
  }
}
// .leftBox,
// .rightBox {
//   display: flex;
//   justify-content: center;
//   width: 50%;
// }
.lastImg {
  display: block;
  width: 271px;
  height: 51px;
}

.googelBtn {
  width: 326px;
  height: 66px;
  margin: 35px 0 50px;
  cursor: pointer;
}
</style>
