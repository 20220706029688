<template>
  <div class="about">
    <headNav activeNav="about"></headNav>
    <!-- <img src="../assets/image/about/banner.png" alt="" class="banner" /> -->
    <div class="banner"></div>
    <div class="wrapper width_common">
      <h1 style="justify-content: center">
        <div class="icon"></div>
        About US
      </h1>
      <div style="margin-bottom: 114px">
        <p>
          Paisayaar belongs to the JINGLECRED DIGITAL FINANCE LIMITED is an
          online loan application. You can get safe and flexible loans through
          Paisayaar. You can choose a variety of methods to receive the loan
          amount. You can also pay your loan through multiple channels and there
          are no hidden charges. Your information is secure with us and used for
          verification only. We will determine the loan amount through the risk
          assessment of your information and credit evaluation.

          <br />
          <br />
          Product information: Loan amount ranges from 1,000-50,000 and the
          maximum loan period is 90 days. The daily interest rate is between
          0%-0.75%. If the repayment is not made on time, a daily penalty
          interest of 2% to 2.5% will be charged.
        </p>
      </div>
    </div>
    <div class="display">
      <div class="left">
        <h1 style="margin-left: 30px">
          <div class="icon"></div>
          Vision & Mission
        </h1>
        <p style="margin-bottom: 30px">
          To open the inclusion of short-term digital credit to the unbanked &
          general population for Pakistan.
        </p>
        <p>
          To allow customers currently not being served by traditional banks to
          become active in the microcredit ecosystem and enable them to build a
          credit identity; in an easy, trustworthy & fast manner.
        </p>
      </div>
      <img src="../assets/image/about/one.png" alt="" />
    </div>
    <div class="wrapper width_common">
      <h1 style="justify-content: center">
        <div class="icon"></div>
        Information Disclosure
      </h1>
      <div
        style="
          margin-left: 100%;
          margin-bottom: 80px;
          transform: translate(-80%);
          width: 100%;
        "
      >
        <p>Company name: JINGLECRED DIGITAL FINANCE LIMITED</p>
        <p>Principal Business: Nano Lendings</p>
        <p>Status of company: NBFC (Investment Finance Service)</p>
        <p>License No. SECP/LRD/78/JDFL/2022-85</p>
        <p style="margin-bottom: 10px">
          Date of License issued: March 10, 2023
        </p>
        <p>
          Outboard Call Number: (+9251) 6011632 - 6011932 (+9251) 8841270 -
          8841289
        </p>
      </div>
    </div>
    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/header.vue";
import footPage from "@/components/foot.vue";

export default {
  data() {
    return {};
  },
  components: {
    headNav,
    footPage,
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.about {
  .banner {
    width: 100%;
    height: 585px;
    background: url("../assets/image/about/banner.png") no-repeat;
    background-size: 100%;
  }
  h1 {
    font-size: 36px;
    font-family: Roboto-Medium, Roboto;
    color: #333333;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
  }
  .icon {
    width: 28px;
    height: 28px;
    background: #2ea76a;
    margin-right: 21px;
    transform: rotate(45deg);
  }
  p {
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    color: #666666;
    line-height: 36px;
  }
  .wrapper {
    padding-top: 70px;
    box-sizing: border-box;
  }
  .display {
    display: flex;
    align-items: center;
    .left {
      margin-left: auto;
      width: 650px;
    }
    img {
      width: 902px;
    }
  }
}

@media only screen and (max-width: 750px) {
  html {
    font-size: 100px;
  }
  .width_common {
    margin: 0 0.33rem;
    width: calc(100% - 0.7rem);
  }
  .about {
    .banner {
      width: 100%;
      height: 3.1rem;
      background: url("../assets/image/about/banner.png") no-repeat;
      background-size: 100% 100%;
    }
    h1 {
      font-size: 0.5rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #333333;
      margin-bottom: 0.16rem;
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
    }
    .icon {
      width: 0.28rem;
      height: 0.28rem;
      background: #2ea76a;
      margin-right: 0.21rem;
      transform: rotate(45deg);
    }
    p {
      font-size: 0.4rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 0.6rem;
    }
    .wrapper {
      padding-top: 0.7rem;
      box-sizing: border-box;
      & > div {
        margin-bottom: 0.28rem !important;
        transform: translate(0) !important;
        margin-left: 0 !important;
      }
    }
    .display {
      // display: flex;
      // align-items: center;
      padding: 0 0.33rem;
      .left {
        width: 100%;
        h1 {
          font-size: 0.5rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          margin-bottom: 0.16rem;
          margin-left: 0 !important;
        }
      }
      img {
        display: none;
      }
    }
  }
}
</style>