<template>
  <div class="foot">
    <div>
      <img src="../assets/image/logo_two.png" alt="" class="logo_two" />
      <img
        src="../assets/image/logo_jingOther.png"
        alt=""
        class="logo_jingOther"
      />
    </div>
    <div class="href">
      <div style="margin-bottom: 14px">
        <a href="https://www.paisayaar.pk/h5/use.html">
          <span>Terms & Conditions</span>
        </a>
      </div>
      <div style="margin-bottom: 14px">
        <!-- run -->
        <a href="https://www.paisayaar.pk/h5/userdata.html">
          <span>User Data Policy</span>
        </a>
      </div>
      <a href="https://www.paisayaar.pk/h5/privacy.html">
        <span>Privacy Policy</span>
      </a>
    </div>
    <div class="iconBox">
      <a
        target="_blank"
        href="https://www.youtube.com/@Paisayaar-Personalonlineloan"
      >
        <img src="../assets/image/youtubeIcon.png" alt="" />
      </a>
      <a
        target="_blank"
        href="https://www.facebook.com/profile.php?id=100091928599507"
      >
        <img src="../assets/image/faceBookIcon.png" alt="" />
      </a>
      <a target="_blank" href="https://twitter.com/Paisayaar">
        <img src="../assets/image/titerIcon.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 750px) {
  html {
    font-size: 100px;
  }
  .foot {
    background: #272928;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.32rem 0.32rem 0.38rem 0.33rem;
    box-sizing: border-box;
    .logo_two {
      width: 3.75rem;
      height: 0.65rem;
      margin-bottom: 0.24rem;
    }
    .logo_jingOther {
      height: 0.65rem;
      width: 2.49rem;
    }
    .href {
      // display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      // margin: 0 2.81rem 0 0.97rem;
      font-size: 0;
      div {
        margin-bottom: 0.25rem !important;
      }

      span {
        font-size: 0.24rem;
        font-family: Roboto-Medium, Roboto;
        color: #ffc029;
        text-decoration: underline;
      }
    }
    .iconBox {
      display: none;
    }
  }
}

@media only screen and (min-width: 750px) {
  .foot {
    position: relative;
    background: #272928;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 26px 0;
    box-sizing: border-box;
    .logo_two {
      width: 374px;
      height: 60px;
      margin-left: 331px;
      // margin-bottom: 13px;
    }
    .logo_jingOther {
      width: 226px;
      height: 60px;
      position: absolute;
      right: 220px;
      top: 50%;
      transform: translateY(-50%);
    }
    .href {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 105px 0 97px;
      span {
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        color: #ffc029;
        text-decoration: underline;
      }
    }
    .iconBox {
      display: flex;
      a {
        margin-right: 28px;
        &:last-child {
          margin: 0;
        }
        img {
          width: 42px;
          height: 42px;
        }
      }
    }
  }
}
</style>
