<template>
  <div>
    <HeaderUrdo activeNav="CalculatorUrdo" />
    <div class="mainContent">
      <div class="content">
        <!-- 头部计算 -->
        <div class="head">
          <div class="totalAmount">
            <h3>Total Repayment Amount：</h3>
            <p>
              {{ totalAmount.toString().replace(/(.{3})/g, "$1,") }}
              PKR
            </p>
          </div>
          <div>
            <h3>Markup：</h3>
            <p>
              {{
                Math.floor(markupAmount)
                  .toString()
                  .replace(/(.{3})/g, "$1,")
              }}
              PKR
            </p>
          </div>
          <div>
            <h3>Overdue Charge:</h3>
            <p>
              {{
                Math.floor(overdueAmount)
                  .toString()
                  .replace(/(.{3})/g, "$1,")
              }}
              PKR
            </p>
          </div>
        </div>
        <!-- 底部计算表单 -->
        <div class="computBox">
          <div>
            <h5>Loan Amount</h5>
            <div>
              <el-input
                maxLength="7"
                @input="changeIpt"
                v-model="loanAmount"
                placeholder="1000 ~ 50000"
              ></el-input>
              <span>PKR</span>
            </div>
          </div>
          <div>
            <h5>Loan Day</h5>
            <div>
              <el-input
                maxLength="4"
                @input="changeIpt2"
                v-model="loanDay"
                placeholder="Please enter"
              ></el-input>
              <span>days</span>
            </div>
          </div>
          <div>
            <h5>Rate</h5>
            <div>
              <el-input
                placeholder="0 ~ 0.75"
                @input="changeIpt3"
                v-model="rate"
              ></el-input>
              <span>%/day</span>
            </div>
          </div>
          <el-divider></el-divider>
          <div style="margin-bottom: 16px">
            <h5>Penalty Rate</h5>
            <div>
              <el-input
                placeholder="0 ~ 2.3"
                @input="changeIpt4"
                v-model="penaltyRate"
              ></el-input>
              <span>%/day</span>
            </div>
          </div>
          <p
            style="
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e73400;
              margin-bottom: 18px;
            "
          >
            Penalty interest will only be added after the repayment date
          </p>
          <div>
            <h5>Overdue Days</h5>
            <div>
              <el-input @input="changeIpt5" v-model="overdueDays"></el-input>
              <span>days</span>
            </div>
          </div>
          <!-- 计算按钮 -->
          <el-button class="computBtnStyle" @click="computBtn"
            >Confirm Calculation</el-button
          >
          <p
            style="
              font-size: 14px;
              font-family: OpenSans-Regular, OpenSans;
              font-weight: 400;
              color: #7c7c7c;
              text-align: center;
              margin-top: 10px;
            "
          >
            The interest and penalties mentioned are based on individual credit
            status.
          </p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <FootUrdo />
  </div>
</template>

<script>
import HeaderUrdo from "@/components/headerUrdo.vue";
import FootUrdo from "@/components/footUrdo.vue";
export default {
  data() {
    return {
      totalAmount: 0, // 总还款金额
      markupAmount: 0, // 利息金额
      overdueAmount: 0, // 罚息金额
      // 计算机属性参数
      loanAmount: null, // 本金
      loanDay: null, // 借款天数
      rate: 0.75, // 利率
      penaltyRate: 2.3, // 罚息
      overdueDays: 0, // 逾期天数
    };
  },
  components: {
    HeaderUrdo,
    FootUrdo,
  },
  methods: {
    // 计算
    computBtn() {
      if (!this.loanAmount) {
        return this.$message.error("Please enter the loan amount");
      } else if (this.loanDay === "") {
        return this.$message.error("Please enter the loan days");
      } else if (this.rate === "") {
        return this.$message.error("Please enter the interest rate (0-2.2%)");
      } else if (this.penaltyRate === "") {
        return this.$message.error(
          "Please enter the penalty interest (0-2.3%)"
        );
      } else if (this.overdueDays === "") {
        return this.$message.error("Please enter the overdue days");
      }
      // 利息金额
      this.markupAmount = this.loanAmount * (this.rate / 100) * this.loanDay;
      // 罚息金额
      this.overdueAmount =
        this.loanAmount * (this.penaltyRate / 100) * this.overdueDays;
      console.log(this.penaltyRate / 100, this.rate / 100);
      // 总还款金额
      this.totalAmount =
        Number(this.loanAmount) +
        Number(this.markupAmount) +
        Number(this.overdueAmount);
      this.$sensors.track("c_calculator_button", {
        // 传递的参数
        source_page: "点击计算", // 点击的页面
      });
    },
    // 总金额判断
    changeIpt(val) {
      this.loanAmount = val.replace(/\D/g, "");
      if (!Number(val)) {
        this.loanAmount = val.slice(0, val.length - 1);
      } else if (this.loanAmount.length > 1 && this.loanAmount[0] == 0) {
        this.loanAmount = this.loanAmount[1];
      }
    },
    // 天数限制
    changeIpt2(val) {
      this.loanDay = val.replace(/\D/g, "");
      if (!Number(val)) {
        this.loanDay = val.slice(0, val.length - 1);
      } else if (this.loanDay.length > 1 && this.loanDay[0] == 0) {
        this.loanDay = this.loanDay[1];
      }
    },
    // 利率限制
    changeIpt3(val) {
      // if (!val) {
      //   this.rate = 0;
      // }
      if (!Number(val) && Number(val) !== 0) {
        this.rate = val.slice(0, val.length - 1);
      }
      if (val.length > 4) {
        this.rate = val.slice(0, 4);
      }
      if (val == 0 && !val.includes(".") && val.length > 1) {
        this.rate = 0;
      }
      if (Number(val) > 2.2) {
        this.rate = 2.2;
      }
    },

    // 罚率限制
    changeIpt4(val) {
      // if (!val) {
      //   this.penaltyRate = 0;
      // }
      if (!Number(val) && Number(val) !== 0) {
        this.penaltyRate = val.slice(0, val.length - 1);
      }
      if (val.length > 4) {
        this.penaltyRate = val.slice(0, 4);
      }
      if (val == 0 && !val.includes(".") && val.length > 1) {
        this.penaltyRate = 0;
      }
      if (Number(val) > 2.3) {
        this.penaltyRate = 2.3;
      }
    },
    // 逾期天数限制
    changeIpt5(val) {
      this.overdueDays = val.replace(/\D/g, "");
    },
  },
  mounted() {
    // this.computBtn();
    this.$sensors.track("v_calculator_page", {
      // 传递的参数
      source_page: "进入计算器页面", // 点击的页面
    });
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.mainContent {
  width: 100%;
  height: 710px;
  background: url("../assets/image/service/computerBgc.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .content {
    width: 856px;
    // height: 630px;
    background: #ffffff;
    border-radius: 25px;
    margin: 40px auto 0px;
    overflow: hidden;
    .head {
      display: flex;
      height: 139px;
      align-items: center;
      width: 100%;
      background: url("../assets/image/service/bg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2ea76a;
      div {
        width: 22%;
        p {
          font-size: 18px;
          color: #fff;
          margin-top: 15px;
        }
      }
      .totalAmount {
        margin-right: 40px;
        padding-left: 31px;
        font-size: 24px;
        width: 50%;
        p {
          font-size: 24px;
        }
      }
    }

    .computBox {
      padding: 20px 28px 20px 32px;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
        h5 {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        div {
          display: flex;
          align-items: center;
          span {
            text-align: right;
            width: 90px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }

      .computBtnStyle {
        display: block;
        width: 370px;
        height: 55px;
        background: #2ea76a;
        border-radius: 20px;
        font-size: 25px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border: none;
        margin: 20px auto 0;
      }

      /deep/ .el-input {
        width: 135px;
        height: 42px;
        border-radius: 8px;
        border: 2px solid #999999;
      }
      /deep/ .el-input__inner {
        border: none;
        height: 100%;
        border-radius: 8px;
        font-size: 18px;
        color: #2ea76a;
        text-align: right;
      }
    }
  }
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* 火狐 */

/deep/ input {
  -moz-appearance: textfield;
}
</style>
