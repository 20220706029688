<template>
  <el-dialog
    title=""
    width="55%"
    v-on="$listeners"
    v-bind="$attrs"
    :show-close="false"
  >
    <div class="contentBox">
      <h2 v-show="status == 'en'">
        Your financial well-being is our priority.
      </h2>
      <h2 v-show="status == 'Urdo'" style="text-align:right">
        آپ کی مالی حالات کی بہتری ہماری پہلی ترجیح ہے۔
      </h2>
      <div v-show="status == 'en'">
        <p>
          Digital Nano loan apps provide quick and easy loans. These Digital
          Nano loans are short-term loans with high-interest rates and
          additional charges. It is essential that you understand potential risk
          of over-indebtedness. Borrow responsibly and only take loans that you
          can comfortably repay within the agreed timeframe to avoid financial
          difficulties. Always read the terms and conditions carefully before
          availing any loan.
        </p>
      </div>
      <div v-show="status == 'Urdo'" style="text-align:right">
        <p>
          ڈیجیٹل لون ایپس قرض کی آسان اور تیز تر فراہمی کو یقینی بناتے ہیں۔ یاد
          رکھیں یہ ڈیجیٹل قرض قلیل مدت کے لئے، غیر معمولی شرح سود اور اضافی
          چارجز پر فراہم کئے جاتے ہیں اور بروقت ادائیگی نہ کرنے پر آپ کا قرض کئ
          گنا بڑھ سکتا ہے۔ لہٰذا قرض کی شرائط و ضوابط کو اچھی طرح سمجھ لیں اور
          صرف ضرورت کے مطابق ہی قرض حاصل کریں جو کہ آپ طے شدہ مدت میں آسانی کے
          ساتھ واپس کر سکیں۔آپ کی مالی بہبود ہماری ترجیح ہے۔
        </p>
      </div>
    </div>
    <el-button class="closeBtn" type="primary" @click="cancel">OK</el-button>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    status: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 32px 66px 40px;
}
.contentBox {
  h2 {
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 25px;
  }
  p {
    font-size: 25px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 45px;
  }
}
.closeBtn {
  display: block;
  width: 330px;
  height: 70px;
  background: #2ea76a;
  border-radius: 75px;
  margin: 35px auto 0;
  font-size: 42px;
  font-family: Helvetica;
  color: #ffffff;
  border: none;
  &:hover {
    background-color: #2ea76a;
  }
}
</style>
