import sensors from "sa-sdk-javascript";

sensors.init({
  server_url: "https://api.paisayaar.pk/risk/t/data/", // 数据接收地址
  use_client_time: true,
  is_track_single_page: true, // 单页应用页面浏览事件采集(url改变就触发)
  use_app_track: true,
  show_log: true, // 控制台显示数据开
  app_key: "ae1d79f1a1d861d7-dev",
  heatmap: {
    clickmap: "default", // 收集点击事件
    scroll_notice_map: "default", // 收集页面区域停留时间
  }, // 热图设置 default开启 not_collect关闭
});
sensors.quick("autoTrack"); // 首次触发页面加载事件 $pageview
export default sensors;
