<template>
  <div>
    <Header class="header" />
    <!-- banner -->
    <div class="bannerBox">
      <a
        href="https://play.google.com/store/apps/details?id=com.personale.credit.carry.cash.loan.paisaya"
        target="blank"
      >
        <img
          src="../../assets/newImage/home/homeGoogle.png"
          alt=""
          class="google"
        />
      </a>
    </div>
    <!-- content -->
    <div class="flowBox">
      <img
        class="flowPathBox"
        src="../../assets/newImage/home/flowPathBoxUrdo.png"
        alt=""
      />
      <h3>پیسہ یار سے رقم کیسے لی جائے؟</h3>
      <img
        class="progressImg"
        src="../../assets/newImage/home/progressUrdo.png"
        alt=""
      />
      <!-- swiper box -->
      <el-carousel
        trigger="click"
        style="margin-bottom: 81px"
        arrow="always"
      >
        <el-carousel-item v-for="item in imgList" :key="item">
          <img :src="item" alt="" style="width:100%;" />
        </el-carousel-item>
      </el-carousel>
      <!-- <img
        src="../../assets/newImage/home/swiperUr.png"
        alt=""
        style="margin-bottom: 81px; width:100%;"
      /> -->
      <!-- import Content -->
      <div class="importBox">
        <h2>اہم اعلان</h2>
        <h5>پیسہیار اپنے صارفین کی حفاظت کو اہمیت دیتا ہے۔</h5>
        <p>
          اگر پیسہ یار کے استعمال کنندگان کو اس کے عملے کے کسی رکن کی طرف سے
          گالی گلوچ، رازداری اور حفاظت کے لیے خطرات وغیرہ کا نشانہ بنایا جاتا
          ہے، تو آپ کی حوصلہ افزائی کی جاتی ہے کہ براہ کرم ثبوت رکھیں اور ہمارے
          پاس شکایت درج کریں۔ پیسہ یار کی طرف سے ایسی حرکتیں برداشت نہیں کی
          جائیں گی۔
        </p>
        <p>
          پیسہ یار کی طرف سے دیا گیا قرض آپ کی تصدیق سے مشروط ہے۔ آپ اپنے قرض کی
          درخواست کے عمل کے دوران "چھوڑیں" بٹن پر کلک کر سکتے ہیں۔ پیسہ یار آپ
          کی رضامندی کے بغیر کبھی بھی قرض ادا نہیں کرے گا۔ اگر آپ نے غلطی سے قرض
          کے لیے درخواست دی ہے، تو براہ کرم 24 گھنٹے کے اندر اصل رقم واپس کر دیں
          اور اپنی جلد سے جلد ہم سے رابطہ کریں۔ ہماری داخلی پالیسیوں کے تابع، ہم
          آپ کے قرض کی درخواست مفت منسوخ کر دیں گے۔ کسی بھی سوالات کے لئے، براہ
          کرم ہم سے رابطہ کرنے میں سنکوچ نہ کریں، ہم آپ کی پوری دل سے خدمت کریں
          گے۔
        </p>
        <p style="margin-bottom: 10px">
          <span>051-111-883-883</span>
          :سروس ہاٹ لائن
        </p>
        <p style="margin-bottom: 10px">
          <span>support@paisayaar.pk</span>
          :شکایت کا ای میل
        </p>
        <p style="margin-bottom: 30px">
          <span>9:00-18:00</span>
          ：سروس ٹائمنگ
        </p>
        <p>
          پیسہیار مندرجہ بالا تمام قواعد کی حتمی تشریح کا حق محفوظ رکھتا ہے۔
        </p>
      </div>
      <!-- 中部banner -->
      <img
        class="centerBanner"
        src="../../assets/newImage/home/centerBannerUrdo.png"
        alt=""
      />
      <!-- about Us -->
      <div class="contentBox">
        <h2>
          <img src="../../assets/newImage/home/aboutIcon.png" alt="" />
          ہمارے بارے میں
        </h2>
        <p>
          پیسہ یار کا تعلق جینگل کریڈ ڈیجیٹل فائنانس لمیٹڈ سے ہے۔ پیسہ یار کے
          ذریعے آپ محفوظ اور آسان قرض حاصل کر سکتے ہیں۔اب قرض کی رقم وصول کرنے
          کے لیے مختلف طریقوں کا انتخاب کر سکتے ہیں۔ قرض کی ادائیگی متعدد چینلز
          کے ذریعے بھی کی جا سکتی ہے اور اس میں کوئی پوشیدہ چارجز نہیں ہیں۔ ہم
          آپ کو یقین دہانی کراتے ہیں کے آپ کی معلومات ہمارے پاس محفوظ ہیں اور آپ
          کی معلومات صرف تصدیق کے مقاصد کے لیے استعمال ہوتی ہیں۔ہم آپ کی دی گئی
          معلومات اور کریڈ ہسٹری کی بنیاد پر قرض کی رقم کا تعین کرتے ہیں۔
        </p>
      </div>

      <!-- vision Mission -->
      <div class="contentBox" style="background: #F8FDFC;">
        <h2>
          <img src="../../assets/newImage/home/visionIcon.png" alt="" />
          وژن اور مشن
        </h2>
        <p>
          پاکستان میں بینکوں سے محروم اور عام آبادی کے لیے قلیل مدتی ڈیجیٹل
          کریڈٹ کی شمولیت کو کھولنا۔
          <br />
          ایک آسان، قابل اعتماد اور تیز انداز میں روایتی بینکوں کی طرف سے فی
          الحال خدمت نہ حاصل کرنے والے صارفین کو مائیکرو کریڈٹ ایکو سسٹم میں
          فعال ہونے کی اجازت دینا اور انہیں کریڈٹ شناخت بنانے کے قابل بنانا۔
        </p>
      </div>

      <!-- Information Disclosu -->
      <div class="contentBox">
        <h2>
          <img src="../../assets/newImage/home/inforIcon.png" alt="" />
          معلومات کا انکشاف
        </h2>
        <p>JINGLECRED DIGITAL FINANCE LIMITED：کمپنی کا نام</p>
        <p>پرنسپل بزنس: نینو لونز</p>
        <p>(انوسٹمنٹ فنانس سروس) NBFC：کمپنی کا اسٹیٹس</p>
        <p style="margin-bottom: 20px">SECP/LRD/78/JDFL/2022-85：ائسنس نمبر</p>
        <!-- <p style="margin-bottom: 20px">
          لائسنس جاری ہونے کی تاریخ: 10 مارچ 2023
        </p> -->
        <p>
          (+9251) 6011632 - 6011932 (+9251) 8841270 - 8841289: آؤٹ بورڈ کال نمبر
        </p>
      </div>
    </div>
    <Footer />
    <TitleDialog @close="close" :status="'Urdo'" :visible.sync="titleVisible" />
  </div>
</template>

<script>
import Header from "../../components/newHeader.vue";
import Footer from "../../components/newFooter.vue";
import TitleDialog from "../../components/titleDialog.vue";
export default {
  components: {
    Header,
    Footer,
    TitleDialog,
  },

  data() {
    return {
      titleVisible: false,
      imgList: [
        require("../../assets/newImage/home/swiperUr.png"),
        require("../../assets/newImage/home/swiperUr1.png"),
        require("../../assets/newImage/home/swiperUr2.png"),
      ],
    };
  },

  mounted() {
    if (!sessionStorage.getItem("newTimeUrdo")) {
      sessionStorage.setItem("newTimeUrdo", new Date());
      this.titleVisible = true;
      // this.$sensors.track("v_web_page", {
      //   // 传递的参数
      //   source_page: "首页", // 点击的页面
      // });
    }
    if (
      document.body.clientWeight <= 750 ||
      document.documentElement.clientWidth <= 750
    ) {
      this.titleVisible = false;
    }
  },

  methods: {
    close() {
      this.titleVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  display: inline-block;
}
.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
}
.bannerBox {
  position: relative;
  width: 100%;
  height: 740px;
  background: url("../../assets/newImage/home/bannerUrdo.png") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    left: 630px;
    top: 500px;
  }
}
.flowBox {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: -60px;
  z-index: 10;
  background-color: #fff;
  border-radius: 56px 56px 0px 0px;
  h3 {
    font-size: 36px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #2c2c34;
  }
  .importBox {
    width: 1441px;
    // height: 763px;
    background: url("../../assets/newImage/home/importBgc.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    text-align: center;
    padding: 72px 0 30px;
    margin-bottom: 75px;
    h2 {
      font-size: 36px;
      font-family: OpenSans-Bold, OpenSans;
      font-weight: bold;
      color: #2c2c34;
      margin-bottom: 8px;
    }
    h5 {
      font-size: 22px;
      font-family: OpenSans-SemiBold, OpenSans;
      font-weight: 600;
      color: #2ea76a;
      margin-bottom: 56px;
    }
    p {
      text-align: right;
      font-size: 20px;
      width: 1201px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #666666;
      margin: 0 auto;
      margin-bottom: 40px;
      line-height: 36px;
      span {
        color: #2ea76a;
        font-weight: 700;
      }
    }
  }

  .contentBox {
    padding: 88px 0 130px;
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-family: OpenSans-Bold, OpenSans;
      font-weight: bold;
      color: #2c2c34;
      margin-bottom: 32px;
      img {
        width: 54px;
        height: 54px;
        margin: 0;
        margin-right: 16px;
      }
    }
    p {
      width: 1400px;
      font-size: 18px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #666666;
      margin: 0 auto;
      line-height: 36px;
    }
  }
}

.google {
  width: 185px;
  height: 55px;
}

.flowPathBox {
  width: 1312px;
  height: 168px;
  margin-top: -83px;
  margin-bottom: 105px;
}

.progressImg {
  width: 879px;
  margin: 74px 0 185px;
}
.centerBanner {
  width: 100%;
  margin-bottom: 85px;
}
/deep/ .el-carousel__arrow {
  width: 50px;
  height: 50px;
  font-size: 26px;
  background-color: rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
/deep/ .el-carousel__container {
  height: 3.2rem;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 20px;
}
</style>
