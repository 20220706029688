<template>
  <div>
    <Header class="header" />
    <div class="banner">
      <img src="../../assets/newImage/faqBannerUrdo.png" alt="" />
      <img
        @click="openGoogel"
        class="googlePlayBox"
        src="../../assets/newImage/home/homeGoogle.png"
        alt=""
      />
    </div>
    <!-- content -->
    <div class="contentList">
      <div class="collapseBox">
        <el-collapse v-model="activeNames" accordion>
          <!-- ------- list1 -------- -->
          <div>
            <el-collapse-item
              v-for="(item, index) in faqContentList"
              :key="index"
              :name="item.name"
            >
              <template slot="title">
                <div>
                  {{ item.title }}
                </div>
                <img src="../../assets/newImage/downIcon.png" alt="" />
              </template>
              <!-- content -->
              <div v-for="row in item.content" :key="row">{{ row }}</div>
            </el-collapse-item>
          </div>
          <!-- ------- list2 -------- -->
          <div>
            <el-collapse-item
              v-for="(item, index) in faqContentList2"
              :key="index"
              :name="item.name"
            >
              <template slot="title">
                <div>
                  {{ item.title }}
                </div>
                <img src="../../assets/newImage/downIcon.png" alt="" />
              </template>
              <!-- content -->
              <div v-for="row in item.content" :key="row">{{ row }}</div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/newHeader.vue";
import Footer from "../../components/newFooter.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeNames: "",
      faqContentList: [
        {
          name: 1,
          title: "What are the eligibilty to get the loan on Paisayar?",
          content: [
            "Citizen of Pakistan.",
            "18 years of age or older.",
            "Person having their own Bank Account / Mobile Wallet.",
          ],
        },

        {
          name: 3,
          title: "Why does Paisayaar collect your personal information?",
          content: [
            "To provide you better loan services and evaluate your Credit History.",
          ],
        },

        {
          name: 5,
          title: "Can I update my Bank Account information in Paisayaar App?",
          content: [
            "Once the Bank Account information is entered it cannot be updated. Please use the Account on which you want to recieve the loan.",
          ],
        },

        {
          name: 7,
          title: "How to receive the loan through Bank Account?",
          content: [
            "By selecting your Bank from the list and entering your Account details.",
          ],
        },

        {
          name: 9,
          title: "What is our loan review process?",
          content: [
            `We will check your Credit history based on the information provided,
            through Automated Risk Assesment Or you may recieve a call from the
            Verification Department.`,
          ],
        },

        {
          name: 11,
          title: "How to repay the loan using Easypaisa?",
          content: [
            "There are three different methods to repay the loan using Easypaisa",
            "Easypaisa App.",
            "Easypaisa USSD.",
            "Easypaisa Retailer.",
          ],
        },

        {
          name: 13,
          title: "Is my information secure?",
          content: [
            `
            Yes, the information you provide is totally secure as we are a
            licensed company and need to abide by government laws to protect
            your information. The information is only used for verification
            purpose.`,
          ],
        },

        {
          name: 15,
          title:
            "On the App there is an option to choose amount from Rs.2,000-Rs.50,000, But the approved amount is low. Why?",
          content: [
            `
            It is determined based upon your credit rating that how much loan
            would be approved. If you pay on time you can get a higher loan in
            future.
          `,
          ],
        },

        {
          name: 17,
          title: "How to cancel my account?",
          content: [
            "Enter the app, click [My Account] - click [Settings] - click [Account and Security] - click [Account Cancellation], and follow the prompts. Or contact customer service 051-111-883-883 or 051-884-127-0 for review. After the customer service review is passed, your account will be cancle within 5 working days.",
          ],
        },
      ],

      faqContentList2: [
        {
          name: 2,
          title: "How to get loans on Paisayaar?",
          content: [
            "Download Paisayaar app from Google Play.",
            "Use the Phone Number that is registered with your CNIC.",
            "Fill in required Personal Information.",
            "The loan can be obtained after the Review is approved.",
          ],
        },
        {
          name: 4,
          title: "Can I update my CNIC information in Paisayaar App?",
          content: [
            'Please ensure that your account is registered with your own CNIC. We only allow one account to be bound to the same CNIC. Once submitted, you can not modify the CNIC information. You are advised to fill your CNIC information carefully before clicking the "NEXT" button.',
          ],
        },
        {
          name: 6,
          title: "How do I receive the loan?",
          content: ["You can use your Mobile Wallet OR Bank Account."],
        },
        {
          name: 8,
          title: "How to receive the loan through Mobile Wallet?",
          content: [
            "You need to have a Mobile Wallet account on the same number as on Paisayaar. We will send the loan to your Mobile Wallet.",
          ],
        },
        {
          name: 10,
          title:
            "Can I register on Paisayaar App through mobile number which is not registered on Mobile Wallet?",
          content: [
            `It is recommended to use the same mobile number to register the App
            that is on your Mobile Wallet account.`,
          ],
        },
        {
          name: 12,
          title:
            "How much time do I have after the due date to return the loan?",
          content: [
            "With each passing day a late payment penalty will be charged. If you still don’t pay, legal action would be taken.",
          ],
        },
        {
          name: 14,
          title: "What is the exact interest based on the number of days?",
          content: [
            `
            The standard rate is 0.75% per day but will be determined based on the
            credit rating.`,
          ],
        },
        {
          name: 16,
          title: "I forgot my Paisayaar App PIN, How can I sign in?",
          content: [
            `
            Dear Customer, Go to the Sign-In page of the Paisayaar App and use
            FORGOT PIN option to reset your PIN, Or tap on the OTP Verification
            to generate OTP.
          `,
          ],
        },
      ],
    };
  },

  methods: {
    openGoogel() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.personale.credit.carry.cash.loan.paisaya"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 10;
}
.banner {
  position: relative;
  background: #13151b;
  img {
    width: 100%;
  }
  .googlePlayBox {
    cursor: pointer;
    position: absolute;
    left: 630px;
    bottom: 177px;
    width: 185px;
    height: 54px;
  }
}
.contentList {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  min-height: 951px;
  // background-color: #1a1a1a;
  background: url("../../assets/newImage/faqBgc.png") no-repeat;
  background-size: 100% 100%;
  margin-top: -140px;
  padding-top: 35px;
  z-index: 15;
}
.collapseBox {
  width: 1272px;
  img {
    width: 12px;
    height: 8px;
  }
}
/deep/ .el-collapse {
  display: flex;
  justify-content: space-between;
  border: none;
  .el-collapse-item {
    width: 616px;
    margin-bottom: 40px;
    .el-collapse-item__header {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 31px;
      width: 100%;
      height: 64px;
      background: #fff;
      border-radius: 16px;
      font-size: 16px;
      font-family: OpenSans-Bold, OpenSans;
      font-weight: bold;
      color: #333;
      line-height: 22px;
      img {
        transition: all 0.3s;
        transform: rotate(0);
      }
    }
    .el-collapse-item__arrow {
      display: none;
    }
    .is-active {
      // border-bottom-left-radius: 0;
      // border-bottom-right-radius: 0;
      img {
        transition: all 0.3s;
        transform: rotate(180deg);
      }
    }
    .el-collapse-item__content {
      width: 616px;
      padding: 32px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      box-sizing: border-box;
      & > div {
        width: 100%;
      }
    }
  }
}
/deep/ .el-collapse-item__wrap {
  // border-bottom-left-radius: 16px;
  // border-bottom-right-radius: 16px;
  border-radius: 16px;
  margin-top: 10px;
  background-color: #fff;
}
</style>
