<template>
  <div>
    <Header class="header" />
    <div class="bannerBox">
      <img src="../../assets/newImage/service/bannerUrdo.png" alt="" />
      <a
        href="https://play.google.com/store/apps/details?id=com.personale.credit.carry.cash.loan.paisaya"
      >
        <img
          class="googleImg"
          src="../../assets/newImage/service/googleplay.png"
          alt=""
        />
      </a>
    </div>
    <!-- content BOX -->
    <div class="contentBox" :class="activeName == 1 ? 'calculator' : 'notify'">
      <!-- tan Box -->
      <!-- <div class="tabBox">
        <div :class="[activeName == 1 ? 'active' : '']" @click="changeTab(1)">
          Calculator
        </div>
        <div :class="[activeName == 2 ? 'active' : '']" @click="changeTab(2)">
          Notify
        </div>
      </div> -->
      <!-- content ---- calculator -->
      <Calculator @changeTab="changeTab" v-show="activeName == 1" />
      <!-- content ---- notify -->
      <div class="notifyBox"></div>
      <NotifyBox @changeTab="changeTab" v-show="activeName == 2" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/newHeader.vue";
import Footer from "../../components/newFooter.vue";
import Calculator from "./serviceComponent/calculator.vue";
import NotifyBox from "./serviceComponent/notifyBox.vue";
export default {
  components: {
    Header,
    Footer,
    Calculator,
    NotifyBox,
  },
  data() {
    return {
      activeName: 1,
    };
  },
  methods: {
    changeTab(row) {
      this.activeName = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.bannerBox {
  position: relative;
  img {
    width: 100%;
  }
  a {
    position: absolute;
    right: 18.5%;
    bottom: 120px;
  }
}
.contentBox {
  position: relative;
  margin-top: -50px;
  overflow: hidden;
  .tabBox {
    display: flex;
    width: 1240px;
    height: 56px;
    background: #ffffff;
    border-radius: 28px;
    overflow: hidden;
    margin: 88px auto 72px;
    & > div {
      cursor: pointer;
      width: 50%;
      text-align: center;
      font-size: 22px;
      font-family: OpenSans-Regular, OpenSans;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
    }
    .active {
      background: #2ea76a;
      border-radius: 28px;
      font-size: 22px;
      font-family: OpenSans-Bold, OpenSans;
      font-weight: bold;
      color: #fff;
      border-radius: 28px;
    }
  }
}

.calculator {
  // height: 1093px;
  padding-bottom: 70px;
  background: url("../../assets/newImage/service/contentBgc.png") no-repeat;
  background-size: 100% 100%;
}
.notify {
  // height: 1656px;
  padding-bottom: 85px;
  background: url("../../assets/newImage/service/contentBgc2.png") no-repeat;
  background-size: 100% 100%;
}

.googleImg {
  width: 185px;
  height: 55px;
}
</style>
